import { gql, useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { GetRepoObjectQueryData, PmxActivity } from "../../types";

export const GET_HISTORIC_REPO_OBJECTS_QUERY = gql`
  query getHistoricRepoObjects(
    $page: Int
    $repoId: ID!
    $historicDatetime: String
    $path: String
    $isTreeView: Boolean
    $actionId: ID
  ) {
    getHistoricRepoObjects(
      input: {
        page: $page
        repoId: $repoId
        historicDatetime: $historicDatetime
        path: $path
        isTreeView: $isTreeView
        actionId: $actionId
      }
    ) {
      repoObjects {
        name
        versionId
        validity {
          qualityCheckStatus
          overallValidityStatus
          userValidityStatus
          systemValidityStatus
          outdatedUserValidityStatus
        }
      }
      isReadOnlyRepository
      repo {
        id
        name
        isStandard
      }
      totalPages
      page
      historicDatetime
    }
  }
`;

export function useGetHistoricRepoObjectsQuery(
  activity: PmxActivity,
  queryParams: object
) {
  const [filesFinal, setFilesFinal] = useState<any[]>([]);
  const [repoIdsState, setRepoIdsState] = useState<any[]>([]);

  const [callRepoObjects, { data: files, loading: isLoading, error }] =
    useLazyQuery(GET_HISTORIC_REPO_OBJECTS_QUERY, {
      fetchPolicy: "no-cache"
    });

  useEffect(() => {
    let repoIds: String[] = [];
    if (activity?.mainRepository?.id && activity?.mainRepository.id !== "0") {
      repoIds = [activity?.mainRepository.id];
      activity?.readOnlyRepositories?.forEach((repo: any) =>
        repoIds.push(repo.id)
      );

      setRepoIdsState(repoIds);

      callRepoObjects({
        variables: {
          ...queryParams,
          repoId: activity?.mainRepository.id,
          page: 1,
          isTreeView: true
        }
      });
    }
  }, [activity]);

  useEffect(() => {
    if (files?.getHistoricRepoObjects) {
      const final = [];
      let wasDataInserted = false;

      filesFinal.forEach((data: GetRepoObjectQueryData) => {
        if (data.repo.id === files?.getHistoricRepoObjects.repo.id) {
          final.push({
            ...data,
            repoObjects: [
              ...data.repoObjects,
              ...files.getHistoricRepoObjects.repoObjects
            ]
          });
          wasDataInserted = true;
        } else {
          final.push(data);
        }
      });

      if (!wasDataInserted) {
        final.push({
          ...files?.getHistoricRepoObjects,
          isReadOnlyRepository:
            files?.getHistoricRepoObjects.repo.id !==
            activity?.mainRepository.id
        });
      }

      setFilesFinal(final);

      if (
        files?.getHistoricRepoObjects.totalPages >
        files?.getHistoricRepoObjects.page
      ) {
        callRepoObjects({
          variables: {
            ...queryParams,
            page: files?.getHistoricRepoObjects.page + 1,
            repoId: files?.getHistoricRepoObjects.repo.id,
            isTreeView: true
          }
        });
      } else {
        if (repoIdsState.length > 0) {
          callRepoObjects({
            variables: {
              ...queryParams,
              repoId: repoIdsState[0],
              page: 1,
              isTreeView: true
            }
          });
          setRepoIdsState(repoIdsState.slice(1));
        }
      }
    }
  }, [files]);

  return {
    files: filesFinal,
    isLoading: isLoading,
    error: error
  };
}
