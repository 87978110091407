import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import RefreshIcon from "@mui/icons-material/Refresh";
import ScheduleSendOutlinedIcon from "@mui/icons-material/ScheduleSendOutlined";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useGetJobsList } from "../../backend/hooks/execution/queryGetJobsList";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import { useUserHelper } from "../../hooks/userHelper";
import Error from "../abstractComponents/error";
import ErrorBoundary from "../abstractComponents/errorBoundary";
import NavBarPmxActivity from "../pmxActivity/navbarPmxActivity";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import {
  convertLocalTimeZone,
  getStatusIcon,
  getStatusTitle
} from "./executionHelpers";
import Radio from "@mui/material/Radio";
import Success from "../abstractComponents/success";
import UserManualLink from "../abstractComponents/usermanual";

export function ExecutionEnvironments() {
  const { activityId, activityData, isActivityLoading } = useContext(
    PmxActivityDetailsContext
  );
  const { isBayerUser } = useUserHelper();
  const [newLimit, setNewLimit] = useState<number>(20);
  const [newCursor, setNewCursor] = useState<string | null>(null);
  const [selectJobId, setSelectJobId] = useState<string | null>(null);
  const refreshJobs: { refreshJobs: boolean } = useLocation().state as {
    refreshJobs: boolean;
  };
  const limit = 20;
  const createdIEEEBridge: { createdIEEEBridge: boolean } = useLocation()
    .state as {
    createdIEEEBridge: boolean;
  };

  const {
    jobList,
    isGetJobsListLoading,
    errorGetJobsList,
    refetchJobList,
    fetchMoreJobs
  } = useGetJobsList(activityId, limit);

  const handleShowMore = () => {
    const updatedLimit = newLimit !== limit ? newLimit : limit;
    fetchMoreJobs({
      variables: {
        activityId: activityId,
        limit: updatedLimit,
        cursor: newCursor
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        fetchMoreResult.getJobs.jobs = [
          ...prevResult.getJobs.jobs,
          ...fetchMoreResult.getJobs.jobs
        ];
        return fetchMoreResult;
      }
    });
  };

  useEffect(() => {
    if (jobList?.getJobs.jobs.length > 0) {
      setNewCursor(() => jobList?.getJobs.nextCursor);
    }
  }, [jobList]);

  useEffect(() => {
    if (refreshJobs) {
      refetchJobList();
      window.history.replaceState({ refreshState: false }, "");
    }
  }, [refreshJobs]);

  const COLUMNS = [
    {
      field: "radiobutton",
      headerName: "",
      sortable: false,
      renderCell: (params: any) => (
        <Radio
          checked={selectJobId === params.row.id}
          value={params.row.id}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSelectJobId(event.target.value);
          }}
        />
      )
    },
    {
      field: "id",
      headerName: "ID",
      renderCell: (params: any) => (
        <div key={params.row.id}>
          <Link
            className="execution_link"
            data-executionname={params.row.name}
            to={`/activity/${activityId}/execution/${params.row.id}`}
          >
            <ScheduleSendOutlinedIcon />
            {params.row.id}
          </Link>
        </div>
      )
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params: any) => (
        <div key={params.row.id}>{params.row.name}</div>
      )
    },
    {
      field: "image",
      headerName: "Image",
      flex: 1,
      renderCell: (params: any) => (
        <div key={params.row.id}>
          <Link
            className="image_link"
            to={{
              pathname: `/activity/${activityId}/execution/${params.row.id}`,
              state: { showImage: true }
            }}
          >
            {params.row.image.name}
          </Link>
        </div>
      )
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 2,
      renderCell: (params: any) => (
        <div className="executions-created-at">
          {convertLocalTimeZone(params.row.createdAt)}
        </div>
      )
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 2
    },
    {
      field: "status",
      headerName: "Status",
      flex: 2,
      renderCell: (params: any) => (
        <div key={params.row.id}>
          {getStatusIcon(params.row.status)} {getStatusTitle(params.row.status)}
        </div>
      )
    },
    {
      field: "environmentVariables",
      headerName: "Environment Variables",
      flex: 2,
      hide: true,
      renderCell: (params: any) => (
        <>
          {params.row.environmentVariables.map((envVar: any) => (
            <Chip
              avatar={
                <Chip
                  className="avatar-parameter"
                  color="primary"
                  label={envVar.name}
                />
              }
              label={envVar.value}
              key={"avatar-parameter" + envVar.name}
            />
          ))}
        </>
      )
    },
    {
      field: "commands",
      headerName: "Commands",
      flex: 1,
      renderCell: (params: any) => (
        <>
          {params.row.commands.map((command: String) => (
            <b key={"avatar-parameter" + command}>{command}</b>
          ))}
        </>
      )
    }
  ];

  return (
    <>
      <NavBarPmxActivity activeIndex={2} />
      <div className="app_content pmx_app_content">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          className="float-start pmx_bradcrumbs"
        >
          <Link to={`/activity/${activityId}`}>
            {activityData?.trialNumber}
          </Link>
          <Typography>Execution Jobs</Typography>
        </Breadcrumbs>
        <br />
        <hr />
        {isBayerUser && activityData && (
          <ErrorBoundary>
            <Container fluid>
              <Row>
                <Col className="d-flex justify-content-end">
                  {(isActivityLoading || isGetJobsListLoading) && (
                    <div className="text-center">
                      <Spinner animation="border" className="spinner_color" />
                      <p>Loading...</p>
                    </div>
                  )}
                </Col>
                <Col className="d-flex justify-content-end gap-2">
                  <Col xs="auto">
                    <UserManualLink
                      url="/usermanual/execution/#jobs-view"
                      testId="um-actions"
                    />
                  </Col>
                  <Col xs="auto">
                    <Button
                      onClick={() => refetchJobList()}
                      id="refresh_list_of_jobs"
                      variant="outline-secondary"
                      className="float-end"
                    >
                      <RefreshIcon /> Refresh
                    </Button>
                  </Col>

                  {selectJobId && (
                    <Col xs="auto">
                      <Link
                        to={`/activity/${activityId}/executions/new?jobId=${selectJobId}`}
                      >
                        <Button id="clone-job" variant="primary">
                          Clone Job
                        </Button>
                      </Link>
                    </Col>
                  )}
                  <Col xs="auto">
                    <Link to={`/activity/${activityId}/executions/new`}>
                      <Button id="create_new_execution" variant="primary">
                        <ScheduleSendOutlinedIcon /> Create New Job
                      </Button>
                    </Link>
                  </Col>
                </Col>
              </Row>
              <br />
              <Row>
                <DataGridPro
                  rows={jobList?.getJobs?.jobs ? jobList?.getJobs?.jobs : []}
                  rowCount={jobList?.getJobs?.totalCount}
                  columns={COLUMNS}
                  autoHeight
                  hideFooterSelectedRowCount
                />
              </Row>
              <br />
              <div className="d-flex justify-content-end gap-4">
                <div>
                  <TextField
                    label="Request Limit"
                    variant="standard"
                    value={newLimit}
                    onChange={(e) =>
                      Number(e.target.value) > 0 &&
                      setNewLimit(Number(e.target.value))
                    }
                    type="number"
                    disabled={isGetJobsListLoading || !newCursor}
                  />
                </div>
                <Button
                  onClick={handleShowMore}
                  variant="primary"
                  disabled={isGetJobsListLoading || !newCursor}
                >
                  Show More
                </Button>
              </div>
            </Container>
            {errorGetJobsList && <Error error={errorGetJobsList} />}
            {createdIEEEBridge && (
              <Success message="Interactive Execution Bridge Job Created. It can take up to 15 Minutes to appear." />
            )}
          </ErrorBoundary>
        )}
      </div>
    </>
  );
}

export default ExecutionEnvironments;
