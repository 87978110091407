import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useGetRepoObject } from "../../backend/hooks/pmxActivity/queryGetRepoObjectForVersionId";
import Error from "../abstractComponents/error";

interface ParamsAction {
  versionId: string;
}

//this component will redirect the users to the proper PMX activity, given the version ID
export function RepoObject() {
  const versionId = (useParams() as ParamsAction).versionId;

  const { repoObject, repoObjectError } = useGetRepoObject(versionId);

  useEffect(() => {
    if (repoObject && repoObject.getRepoObject.repo) {
      const url =
        "/activity/" +
        repoObject.getRepoObject.repo.mainActivityId +
        encodeURIComponent("/" + repoObject.getRepoObject.name) +
        encodeURIComponent(
          "?versionId=" +
            versionId +
            "&repoId=" +
            repoObject.getRepoObject.repo.id +
            "&openProperties=true"
        );
      window.location.replace(url);
    }
  }, [repoObject]);

  return (
    <>
      <div className="center_div">
        <Spinner animation="border" className="spinner_color" />
        <p>Redirecting...</p>
        {repoObjectError && <Error error={repoObjectError} />}
      </div>
    </>
  );
}
