import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { Job } from "../../executionTypes";

export const GET_JOB = gql`
  query getJobs($jobId: ID!) {
    getJobs(input: { jobId: $jobId }) {
      jobs {
        id
        activityId
        name
        description
        status
        statusReason
        createdAt
        startedAt
        stoppedAt
        createdBy
        image {
          id
          name
          description
          environmentType
          environmentTypeVersion
          validationType
          validationTypeVersion
          digest
          updatedOn
          packages {
            name
            version
          }
          validatedPackages {
            name
            version
          }
          addons {
            name
          }
        }
        actionId
        actionType
        qualityCheckStatus
        entrypoint {
          repositoryId
          versionId
        }
        inputs {
          repositoryId
          versionId
        }
        inputFolderStructure
        workingDir
        userCommand
        commands
        numOfNodes
        environmentVariables {
          name
          value
        }
        vCPU
        memory
        timeout
        ignorePatterns
        describePatterns
        parentJob {
          id
        }
        interactiveEnvironmentId
      }
    }
  }
`;

export function useGetJob(jobId: string) {
  const {
    data: getJobsList,
    loading: isGetJobLoading,
    error: errorGetJob,
    refetch: refetchJob
  } = useQuery(GET_JOB, {
    variables: { jobId },
    context: { clientName: "execution" },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only"
  });

  const job = getJobsList
    ? (getJobsList?.getJobs?.jobs[0] as Job)
    : ({} as Job);
  return {
    job,
    isGetJobLoading,
    errorGetJob,
    refetchJob
  };
}

export function useLazyGetJob() {
  const [
    getJobs,
    { data: getJobsList, loading: isGetJobLoading, error: errorGetJob }
  ] = useLazyQuery(GET_JOB, {
    context: { clientName: "execution" },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only"
  });

  const job = getJobsList
    ? (getJobsList?.getJobs?.jobs[0] as Job)
    : ({} as Job);
  return {
    getJobs,
    job,
    isGetJobLoading,
    errorGetJob
  };
}
