import Badge from "@mui/material/Badge";
import EditAction from "../editAction";
import { useState, useEffect, useRef } from "react";
import { useCreationalActionsLazyQuery } from "../../backend/hooks/contextMenu/queryGetCreationalActions";
import { Spinner } from "react-bootstrap";
import {
  QualityCheckStatus,
  RepoObjectValidity,
  ValidityStatus
} from "../../backend/types";
import { checkIfActionIsNotEditable } from "../../helpers/actionHelper";
import Person2Icon from "@mui/icons-material/Person2";
import WarningIcon from "@mui/icons-material/Warning";
import { waitForClass } from "../../helpers/stringHelper";

// Component used to render circle for a file related to the creating action
const QcActionFlag = (props: {
  children?: JSX.Element;
  versionId: String;
  refetch?: Function;
  validity?: RepoObjectValidity | null;
}) => {
  const [imageNameForUsage, setImageNameForUsage] = useState(
    "Bayer-PHIL-Icon-Circle-Grey_svg.svg"
  );
  const [titleForUsage, setTitleForUsage] = useState("");
  const [showActionPopup, setShowActionPopup] = useState(false);
  const [currentAction, setCurrentAction] = useState<any>();
  const parentRef = useRef(null);
  const [renderChildred, setRenderChildren] = useState<any>();

  useEffect(() => {
    let children = props.children;
    if (
      props?.validity?.userValidityStatus &&
      props?.validity?.userValidityStatus !== ValidityStatus.NotSet
    ) {
      children = (
        <Badge
          overlap="circular"
          className="no_background user_validity_badge"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          onClick={onClickValidityBadge}
          title={"Validity Manually Set"}
          badgeContent={
            <Person2Icon
              className="user_validity_badge_content small_validity_icon"
              color="secondary"
            />
          }
        >
          {children}
        </Badge>
      );
    }

    if (props?.validity?.outdatedUserValidityStatus) {
      children = (
        <Badge
          overlap="circular"
          className="no_background outdated_validity_badge"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          onClick={onClickValidityBadge}
          title={"Validity Outdated By Another File"}
          badgeContent={
            <WarningIcon
              className="outdated_validity_badge_content small_validity_icon"
              color="warning"
            />
          }
        >
          {children}
        </Badge>
      );
    }

    setRenderChildren(children);
  }, [props.children, props.validity]);

  const {
    getCreationalActions,
    creationalActions,
    isLoadingCreationalActions,
    refetchCreationalActions
  } = useCreationalActionsLazyQuery();

  useEffect(() => {
    props?.validity?.qualityCheckStatus &&
      setImageNameFromAction(props?.validity?.qualityCheckStatus);
  }, [props.validity?.qualityCheckStatus]);

  useEffect(() => {
    if (showActionPopup) {
      getCreationalActions({
        variables: {
          versionIds: [props.versionId]
        }
      });
    }
  }, [showActionPopup]);

  const setImageNameFromAction = (currentLoopingAction: QualityCheckStatus) => {
    switch (currentLoopingAction) {
      case QualityCheckStatus.Successful:
        setImageNameForUsage("Bayer-PHIL-Icon-Circle-Green_svg.svg");
        setTitleForUsage("Creating Action Successful");
        break;
      case QualityCheckStatus.Marked:
        setImageNameForUsage("Bayer-PHIL-Icon-Circle-Blue_svg.svg");
        setTitleForUsage("Creating Action QC is Marked");
        break;
      case QualityCheckStatus.Failed:
        setImageNameForUsage("Bayer-PHIL-Icon-Circle-Red_svg.svg");
        setTitleForUsage("Creating Action's QC Failed");
        break;
      case QualityCheckStatus.NotStarted:
        setImageNameForUsage("Bayer-PHIL-Icon-Circle-Grey_svg.svg");
        setTitleForUsage("Creating Action's QC Not Started");
        break;
      default:
        setImageNameForUsage("Bayer-PHIL-Icon-Circle-Grey_svg.svg");
        setTitleForUsage("Creating Action's QC Not Started");
    }
  };

  useEffect(() => {
    if (creationalActions && creationalActions.getCreationalActions.actions) {
      const currentLoopingAction =
        creationalActions.getCreationalActions.actions[0];

      if (currentLoopingAction !== null) {
        setCurrentAction(currentLoopingAction);
        setImageNameFromAction(currentLoopingAction?.actionQualityCheckStatus);
      }
    }
  }, [creationalActions]);

  const onClickValidityBadge = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    // get menu and open properties from there
    const link = parentRef.current
      .closest(".MuiDataGrid-row")
      .querySelector(".inner_menu_button");

    if (link) {
      link.click();
      waitForClass("properties_file_button", (element: HTMLElement) => {
        element.click();
      });
    }

    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <>
      <Badge
        ref={parentRef}
        overlap="circular"
        className="no_background qc_badge"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        onClick={() => {
          setShowActionPopup(true);
        }}
        badgeContent={
          isLoadingCreationalActions ? (
            <Spinner animation="grow" size="sm" variant="secondary" />
          ) : (
            <img
              title={titleForUsage}
              src={"/images/" + imageNameForUsage}
              alt="QC Flag"
              className={
                "qc_badge qc_circle_flag " + imageNameForUsage.split(".")[0]
              }
            />
          )
        }
      >
        <>{renderChildred}</>
      </Badge>

      {currentAction && showActionPopup && !isLoadingCreationalActions && (
        <EditAction
          actionId={currentAction.id}
          openDirectly={true}
          onClose={() => {
            setShowActionPopup(false);
          }}
          refetch={() => {
            if (props.refetch) {
              props.refetch();
            }
            refetchCreationalActions();
          }}
          notEditableAction={checkIfActionIsNotEditable(
            currentAction.actionType
          )}
        />
      )}
    </>
  );
};

export default QcActionFlag;
