import { useMutation } from "@apollo/client";
import Download from "@mui/icons-material/Download";
import Preview from "@mui/icons-material/Preview";
import RestoreIcon from "@mui/icons-material/Restore";
import Box from "@mui/material/Box";
import { lighten } from "@mui/material/styles";
import {
  DataGridPro,
  GridActionsCellItem,
  GridCellParams,
  GridRowId,
  GridSortDirection,
  GridSortModel,
  GridValueGetterParams
} from "@mui/x-data-grid-pro";
import { useContext, useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  Modal,
  Spinner,
  Tab,
  Tabs
} from "react-bootstrap";
import { UPDATE_ACTION_STATUS_MUTATION } from "../../backend/hooks/action/mutationUpdateActionStatus";
import { useConsumingActionsLazyQuery } from "../../backend/hooks/contextMenu/queryGetConsumingActions";
import { useCreationalActionsLazyQuery } from "../../backend/hooks/contextMenu/queryGetCreationalActions";
import { useHistoryVersionsLazyQuery } from "../../backend/hooks/contextMenu/queryGetHistoryVersions";
import { useRestoreFileVersionMutation } from "../../backend/hooks/history/mutationRestoreFileVersion";
import { useDownloadLinksQuery } from "../../backend/hooks/importExportMenu/queryGetDownloadLinks";
import {
  Action,
  ActionFileType,
  ActionStatus,
  RepoObject,
  ValidityStatus
} from "../../backend/types";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import { formatFileSize, getKeyByValue } from "../../helpers/stringHelper";
import { customFilters } from "../../helpers/tableViewHelper";
import ActionTable from "../abstractComponents/actionTable";
import Error from "../abstractComponents/error";
import Success from "../abstractComponents/success";
import UserManualLink from "../abstractComponents/usermanual";
import UserFileValidity from "./userFileValidity";

// Component that appears when we go to a file inside ot Table Veiw -> Select a file -> Properties
export default function ActionsOfFiles(props: {
  onNodeSelect: Function;
  onClose: Function;
  absolutePath: String;
  versionId: String;
  isPreselectedRevision: Boolean;
  setIsPreselectedRevision: Function;
  repoId: String;
  refetch?: Function;
  isHistoricalView?: Boolean;
}) {
  // Set functions
  const columnsFileVersions = [
    {
      field: "revision",
      headerName: "Revision",
      flex: 1,
      filterOperators: customFilters,
      valueGetter: (params: GridCellParams) =>
        params.row.md5 ? params.row.revision : params.row.revision + " (hidden)"
    },
    {
      field: "name",
      headerName: "Path",
      flex: 1,
      filterOperators: customFilters,
      renderCell: (params: any) => (
        <div className="action_list_element">{params.row.name}</div>
      )
    },
    {
      field: "created",
      headerName: "Created At",
      flex: 1,
      filterOperators: customFilters
    },
    {
      field: "createdBy",
      headerName: "User",
      flex: 1,
      filterOperators: customFilters
    },
    {
      field: "size",
      headerName: "Size",
      flex: 1,
      filterOperators: customFilters,
      valueGetter: (params: GridValueGetterParams) =>
        formatFileSize(Number(params.row.size))
    },
    {
      field: "overallValidity",
      headerName: "Overall Validity",
      flex: 1,
      filterOperators: customFilters,
      valueGetter: (params: GridValueGetterParams) =>
        getKeyByValue(
          ValidityStatus,
          params.row.validity?.overallValidityStatus
        )
    },
    {
      field: "systemValidity",
      headerName: "System File Validity",
      flex: 1,
      filterOperators: customFilters,
      valueGetter: (params: GridValueGetterParams) =>
        getKeyByValue(ValidityStatus, params.row.validity?.systemValidityStatus)
    },
    {
      field: "userValidity",
      headerName: "User File Validity",
      flex: 1,
      filterOperators: customFilters,
      valueGetter: (params: GridValueGetterParams) =>
        getKeyByValue(ValidityStatus, params.row.validity?.userValidityStatus)
    },
    {
      field: "outdatedUserValidityStatus",
      headerName: "Is Outdated by User File Validity",
      flex: 1
    },
    {
      field: "md5",
      headerName: "MD5",
      flex: 1,
      filterOperators: customFilters
    },
    {
      field: "actions",
      type: "actions",
      flex: 1,
      filterOperators: customFilters,
      getActions: (params: GridValueGetterParams) => [
        <GridActionsCellItem
          icon={
            <RestoreIcon
              className="restore_from_version_id"
              style={{
                fontSize: 40
              }}
            />
          }
          label="Restore"
          title="Restore"
          onClick={() => {
            setVersionIdToBeRestored(params.row.versionId);
            setIsRestoreModalVisible(true);
            setAbsolutePathToBeRestored(params.row.name);
          }}
          onResize={() => {}}
          onResizeCapture={() => {}}
          showInMenu={false}
          key={"restore" + params.row.versionId}
        />,
        <GridActionsCellItem
          icon={
            <Preview
              data-testid={getPreviewURL(params.row.versionId, params.row.name)}
              className="preview_button"
              style={{
                fontSize: 40
              }}
            />
          }
          label="Preview"
          title="Preview"
          onClick={() => handlePreview(params.row.versionId, params.row.name)}
          onResize={() => {}}
          onResizeCapture={() => {}}
          showInMenu={false}
          key={"preview_button" + params.row.versionId}
        />,
        <GridActionsCellItem
          icon={
            <Download
              className="download_button"
              style={{
                fontSize: 40
              }}
            />
          }
          label="Download"
          title="Download"
          onClick={() =>
            handleDownloadFile(params.row.name, params.row.versionId)
          }
          onResize={() => {}}
          onResizeCapture={() => {}}
          showInMenu={false}
          key={"download_button" + params.row.versionId}
        />
      ]
    }
  ];

  const { activityId } = useContext(PmxActivityDetailsContext);

  const [revisionsToId, setRevisionsToId] = useState<any>({});
  const [versionIds, setVersionIds] = useState<string[]>([]);
  const [selectedRevision, setSelectedRevision] = useState("");
  const [selectedVersionId, setSelectedVersionId] = useState(props.versionId);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [sortModelVersions, setSortModelVersions] = useState<GridSortModel>([
    {
      field: "created",
      sort: "desc" as GridSortDirection
    }
  ]);
  const [comment, setComment] = useState("");
  const [isRestoreModalVisible, setIsRestoreModalVisible] = useState(false);
  const [versionIdToBeRestored, setVersionIdToBeRestored] =
    useState<String | null>();
  const [absolutePathToBeRestored, setAbsolutePathToBeRestored] =
    useState<String | null>();

  // Query functions
  const { getFileVersions, fileVersions, isLoading, error } =
    useHistoryVersionsLazyQuery();

  const {
    getCreationalActions,
    creationalActions,
    isLoadingCreationalActions,
    errorCreationalActions,
    refetchCreationalActions
  } = useCreationalActionsLazyQuery();

  const refetchAllActions = () => {
    refetchCreationalActions();
    refetchConsumingActions();
    if (props.refetch) {
      props.refetch();
    }
  };

  const {
    restoreFileVersionMutation,
    isLoadingRestoreMutation,
    errorRestoreMutation,
    dataMutation
  } = useRestoreFileVersionMutation();

  const {
    getConsumingActions,
    consumingActions,
    isLoadingConsumingActions,
    errorConsumingActions,
    refetchConsumingActions
  } = useConsumingActionsLazyQuery();

  const { getDownloadLinkForFiles, downloadUrls, errorDownload } =
    useDownloadLinksQuery();

  const [updateActionStatus, { data: statusForUpdatedAction }] = useMutation(
    UPDATE_ACTION_STATUS_MUTATION,
    {
      onError(err: any) {
        console.log(err);
      }
    }
  );

  const handleDownloadFile = (absolutePath: string, versionId: string) => {
    getDownloadLinkForFiles({
      variables: {
        absolutePaths: [absolutePath],
        activityId: Number(activityId),
        repoId: Number(props.repoId),
        versionIds: [versionId]
      }
    });
  };

  const handleGetRowIdVersions = (row: any) => {
    return row.versionId;
  };

  const getPreviewURL = (versionId: string, absolutePath: string) => {
    return (
      "/activity/" +
      activityId +
      encodeURIComponent("/" + absolutePath) +
      encodeURIComponent("?versionId=" + versionId)
    );
  };

  const handlePreview = (versionId: string, absolutePath: string) => {
    window.location.href = getPreviewURL(versionId, absolutePath);
  };

  const handleCellClick = (params: GridCellParams) => {
    if (
      params.row.versionId !== selectedVersionId &&
      params.field !== "actions" &&
      params.row.name
    ) {
      setSelectedRevision(params.row.revision.toString());
      setSelectedVersionId(params.row.versionId);
    } else {
      setSelectedRevision("");
      setSelectedVersionId("");
    }
  };

  useEffect(() => {}, [selectedRevision, selectedVersionId]);

  useEffect(() => {
    const setRevisions = (actions: Action[], fileTypeCheck: Function) => {
      const revisions = {};
      actions?.forEach((action) => {
        action.actionFiles.forEach((af) => {
          if (
            versionIds.includes(af.file.versionId) &&
            fileTypeCheck(af.actionFileType)
          ) {
            revisions[action.id] = af.file.revision;
          }
        });
      });
      return revisions;
    };

    if (!isLoadingCreationalActions && creationalActions) {
      setRevisionsToId((prev) => ({
        ...prev,
        ...setRevisions(
          creationalActions.getCreationalActions.actions,
          (type: ActionFileType) =>
            type !== ActionFileType.Input && type !== ActionFileType.Script
        )
      }));
    }

    if (!isLoadingConsumingActions && consumingActions) {
      setRevisionsToId((prev) => ({
        ...prev,
        ...setRevisions(
          consumingActions.getConsumingActions.actions,
          (type: ActionFileType) =>
            type === ActionFileType.Input || type === ActionFileType.Script
        )
      }));
    }
  }, [
    creationalActions,
    consumingActions,
    isLoadingCreationalActions,
    isLoadingConsumingActions
  ]);

  useEffect(() => {
    if (typeof downloadUrls !== "undefined") {
      if (downloadUrls.getDownloadLinks.actionStatus === ActionStatus.Pending) {
        try {
          downloadUrls?.getDownloadLinks?.presignedUrls.forEach((url: any) => {
            window.open(url.url);
          });
          updateActionStatus({
            variables: {
              actionId: downloadUrls.getDownloadLinks.actionId,
              actionStatus: ActionStatus.Success
            }
          });
        } catch (err: any) {
          updateActionStatus({
            variables: {
              actionId: downloadUrls.getDownloadLinks.actionId,
              actionStatus: ActionStatus.Error,
              actionStatusDescription: err.message
            }
          });
        }
      }
    }
  }, [downloadUrls]);

  useEffect(() => {
    if (
      statusForUpdatedAction &&
      statusForUpdatedAction?.updateActionStatus.actionStatus ===
        ActionStatus.Success
    ) {
      getConsumingActions({
        variables: {
          versionIds: versionIds,
          activityId: activityId
        }
      });
    }
  }, [statusForUpdatedAction]);

  // Query file versions
  useEffect(() => {
    if (props.versionId) {
      getFileVersions({
        variables: {
          repoId: props.repoId,
          activityId: Number(activityId),
          versionId: props.versionId
        }
      });
    }
  }, [props.versionId, props.absolutePath]);

  useEffect(() => {
    if (fileVersions && fileVersions.getHistoryVersions.length > 0) {
      const versionIds = fileVersions.getHistoryVersions.map(
        (fileVersion: RepoObject) => fileVersion.versionId
      );
      setVersionIds(versionIds);
      setSelectionModel([
        fileVersions.getHistoryVersions[0].versionId as string
      ]);
      setSelectedRevision(
        fileVersions.getHistoryVersions[0].revision.toString()
      );
      setSelectedVersionId(fileVersions.getHistoryVersions[0].versionId);
    }
  }, [fileVersions]);

  useEffect(() => {
    if (selectedVersionId !== "") {
      getCreationalActions({
        variables: {
          versionIds: [selectedVersionId],
          activityId: activityId
        }
      });
      getConsumingActions({
        variables: {
          versionIds: [selectedVersionId],
          activityId: activityId
        }
      });
    }
  }, [selectedVersionId]);

  const selectionModelChange = (selection: any) => {
    if (selection.length > 1) {
      const selectionSet = new Set(selectionModel);
      const result = selection.filter((s: any) => !selectionSet.has(s));
      setSelectionModel(result);
    } else {
      setSelectionModel(selection);
    }
  };

  const restoreSelectedPaths = () => {
    restoreFileVersionMutation({
      variables: {
        versionIds: [versionIdToBeRestored],
        activityId: activityId,
        comment: comment
      }
    }).then(() => {
      window.location.href =
        "/activity/" +
        activityId +
        encodeURIComponent("/" + absolutePathToBeRestored) +
        encodeURIComponent("?repoId=" + props.repoId + "&openProperties=true");
    });
    setIsRestoreModalVisible(false);
  };

  useEffect(() => {
    props.versionId &&
      props.isPreselectedRevision &&
      setSelectionModel([props.versionId as string]);
  }, [props.versionId, props.isPreselectedRevision]);

  return (
    <>
      <Modal
        enforceFocus={false}
        show
        onHide={() => props.onClose()}
        dialogClassName="modal-80w"
        aria-labelledby="custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="custom-modal-styling-title">
            Properties of {props.absolutePath}
          </Modal.Title>
          <UserManualLink
            url="/usermanual/properties/"
            testId="um-properties"
            className="align-self-start ms-1"
          />
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <>
              {isLoading || isLoadingRestoreMutation ? (
                <div className="center_div">
                  <Spinner animation="border" className="spinner_color" />
                  <p>Loading...</p>
                </div>
              ) : error ||
                errorCreationalActions ||
                errorConsumingActions ||
                errorDownload ||
                errorRestoreMutation ? (
                <Error
                  error={
                    error ||
                    errorCreationalActions ||
                    errorConsumingActions ||
                    errorDownload ||
                    errorRestoreMutation
                  }
                />
              ) : (
                <>
                  {fileVersions && fileVersions.getHistoryVersions && (
                    <>
                      <h5>File Versions:</h5>
                      <div className="file_versions_table">
                        <Box
                          sx={{
                            width: 1,
                            "& .super-app-theme--Hidden": {
                              bgcolor: lighten("#e4a3a3", 0.6),
                              "&:hover": {
                                bgcolor: lighten("#e4a3a3", 0.5)
                              }
                            }
                          }}
                        >
                          {/** Historical View File Peopertis will always display the latest entry as not hidden, irrespective of the current status **/}
                          <DataGridPro
                            rows={
                              props.isHistoricalView
                                ? [
                                    {
                                      ...fileVersions.getHistoryVersions.at(0),
                                      md5: fileVersions.getHistoryVersions.at(0)
                                        .md5
                                        ? fileVersions.getHistoryVersions.at(0)
                                            .md5
                                        : "   "
                                    },
                                    ...fileVersions.getHistoryVersions.filter(
                                      (item: any) =>
                                        item.versionId !==
                                        fileVersions.getHistoryVersions.at(0)
                                          .versionId
                                    )
                                  ]
                                : fileVersions.getHistoryVersions
                            }
                            className="styled_data_grid"
                            columns={columnsFileVersions}
                            pageSize={25}
                            autoHeight
                            getRowHeight={() => "auto"}
                            checkboxSelection
                            selectionModel={selectionModel}
                            getRowClassName={(params) =>
                              params.row.md5 ? "" : "super-app-theme--Hidden"
                            }
                            hideFooterSelectedRowCount
                            getRowId={handleGetRowIdVersions}
                            onCellClick={handleCellClick}
                            onSelectionModelChange={selectionModelChange}
                            sortModel={sortModelVersions}
                            onSortModelChange={(model) =>
                              setSortModelVersions(model)
                            }
                          />
                        </Box>
                      </div>

                      <Modal
                        size="lg"
                        show={isRestoreModalVisible}
                        onHide={() => setIsRestoreModalVisible(false)}
                        className="modal_over_popup"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Restore Item</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicName"
                          >
                            <Form.Label>
                              Comment <span className="red_form">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              required
                              placeholder="Comment"
                              id="set_comment"
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                            />
                          </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={() => setIsRestoreModalVisible(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="primary"
                            disabled={comment === ""}
                            onClick={restoreSelectedPaths}
                          >
                            <RestoreIcon /> Restore Item
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </>
                  )}

                  <Tabs
                    defaultActiveKey="userFileValidityStatus"
                    id="filePropertiesTabs"
                    className="mb-3"
                  >
                    <Tab
                      eventKey="userFileValidityStatus"
                      title="User File Validity"
                    >
                      <UserFileValidity
                        versionId={
                          selectedVersionId !== ""
                            ? selectedVersionId
                            : props.versionId
                        }
                        refetch={() => {
                          if (props.refetch) {
                            props.refetch();
                          }
                          getFileVersions({
                            variables: {
                              repoId: props.repoId,
                              activityId: Number(activityId),
                              versionId: props.versionId
                            }
                          });
                        }}
                      />
                    </Tab>
                    <Tab eventKey="creatingActions" title="Creating Action">
                      {isLoadingCreationalActions && (
                        <div className="center_div">
                          <Spinner
                            animation="border"
                            className="spinner_color"
                          />
                          <p>Loading...</p>
                        </div>
                      )}
                      {creationalActions?.getCreationalActions?.actions
                        ?.length > 0 && (
                        <>
                          <br />
                          <h5>Creating Actions:</h5>
                          <div className="creational_actions_table">
                            <ActionTable
                              rowsActions={
                                creationalActions?.getCreationalActions?.actions
                              }
                              refetch={refetchAllActions}
                              setIsPreselectedRevision={
                                props.setIsPreselectedRevision
                              }
                              revisionsToId={revisionsToId}
                              onNodeSelect={props.onNodeSelect}
                              onClose={props.onClose}
                            />
                          </div>
                        </>
                      )}
                    </Tab>

                    <Tab eventKey="consumingActions" title="Consuming Actions">
                      {isLoadingConsumingActions && (
                        <div className="center_div">
                          <Spinner
                            animation="border"
                            className="spinner_color"
                          />
                          <p>Loading...</p>
                        </div>
                      )}
                      {consumingActions?.getConsumingActions?.actions?.length >
                      0 ? (
                        <>
                          <h5>Consuming Actions:</h5>
                          <div className="consuming_actions_table">
                            <ActionTable
                              rowsActions={
                                consumingActions.getConsumingActions.actions
                              }
                              refetch={refetchAllActions}
                              setIsPreselectedRevision={
                                props.setIsPreselectedRevision
                              }
                              revisionsToId={revisionsToId}
                              onNodeSelect={props.onNodeSelect}
                              onClose={props.onClose}
                            />
                          </div>
                        </>
                      ) : (
                        <p>No Consuming Actions</p>
                      )}
                    </Tab>
                  </Tabs>
                </>
              )}
            </>
          </Container>
        </Modal.Body>
      </Modal>
      {dataMutation && <Success message="File was restored" />}
    </>
  );
}
