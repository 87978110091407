import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner, Table, Modal } from "react-bootstrap";
import { useCreateUserValidation } from "../../backend/hooks/pmxActivity/qualityCheck/mutationCreateUserValidation";
import { useGetUserValidations } from "../../backend/hooks/pmxActivity/qualityCheck/queryGetUserValidations";
import {
  ValidityStatusType,
  ValidityStatus,
  UserValidation
} from "../../backend/types";
import { Link } from "react-router-dom";
import Error from "../abstractComponents/error";
import { getKeyByValue } from "../../helpers/stringHelper";
import UserManualLink from "../abstractComponents/usermanual";

// Component that appears when we go to a file inside ot Table Veiw -> Select a file -> Properties
export default function UserFileValidity(props: {
  versionId: String;
  refetch: Function;
}) {
  const [userValidityDescription, setUserValidityDescription] = useState("");
  const [selectedUserValidity, setSelectedUserValidity] = useState(
    ValidityStatus.NotSet
  );
  const [showSaveAlert, setShowSaveAlert] = useState(false);

  const {
    createUserValidation,
    isLoadingCreateUserValidation,
    errorCreateUserValidation
  } = useCreateUserValidation();

  const {
    userValidations,
    refetchUserValidation,
    isLoadingValidationQuery,
    errorValidationQuery
  } = useGetUserValidations(props.versionId);

  useEffect(() => {
    if (
      userValidations &&
      userValidations?.getUserValidations?.userValidations[0]
    ) {
      setSelectedUserValidity(
        userValidations.getUserValidations?.userValidations[0].validityStatus
      );
      setUserValidityDescription(
        userValidations.getUserValidations?.userValidations[0].description
      );
    } else {
      setSelectedUserValidity(ValidityStatus.NotSet);
      setUserValidityDescription("");
    }
  }, [userValidations, props.versionId]);

  const saveUserValidity = () => {
    createUserValidation({
      variables: {
        versionId: props.versionId,
        validityStatus: selectedUserValidity as ValidityStatusType,
        description: userValidityDescription
      }
    }).then(() => {
      refetchUserValidation();
      setSelectedUserValidity("");
      setUserValidityDescription("");
      props.refetch();
      setShowSaveAlert(false);
    });
  };

  const getPreviewURL = (versionId: string) => {
    const URL = "/repoObject/" + versionId;
    window?.open(URL, "_blank");
  };

  return (
    <>
      <Row>
        <Col xs={2}>
          Status <span className="red_form">*</span>
        </Col>
        <Col xs={4}>
          <Form.Select
            aria-label="Status"
            className="status_select_popup"
            value={selectedUserValidity}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
              event.preventDefault();
              setSelectedUserValidity(event.target.value);
            }}
          >
            {Object.values(ValidityStatus).map((value) => (
              <option key={value} value={value}>
                {getKeyByValue(ValidityStatus, value)}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col xs={1}>
          <UserManualLink
            url="/usermanual/qualitycontrol/#editing-user-validity"
            testId="um-outdated"
            className="align-self-start ms-1"
          />
        </Col>

        <Col xs={1}>
          {isLoadingCreateUserValidation && (
            <Spinner animation="border" className="spinner_color" size="sm" />
          )}
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={2}>
          Description <span className="red_form">*</span>
        </Col>
        <Col xs={4}>
          <Form.Control
            as="textarea"
            placeholder="Leave a comment here"
            className="description_textarea"
            style={{ height: "100px" }}
            value={userValidityDescription}
            onChange={(e) => setUserValidityDescription(e.target.value)}
          />
        </Col>
        <Col xs={1}>
          <Button
            disabled={
              userValidityDescription === "" ||
              userValidityDescription ===
                userValidations?.getUserValidations?.userValidations[0]
                  ?.description
            }
            onClick={() => setShowSaveAlert(true)}
            className="button_save_inner"
          >
            {userValidations?.getUserValidations?.userValidations[0]
              ?.description
              ? "Update"
              : "Save"}
          </Button>
        </Col>
      </Row>
      {isLoadingValidationQuery && (
        <div className="center_div">
          <Spinner animation="border" className="spinner_color" />
          <p>Loading...</p>
        </div>
      )}
      {userValidations &&
      userValidations?.getUserValidations?.userValidations?.length === 0 ? (
        <p>No History found</p>
      ) : (
        <>
          <br />
          <h4>History</h4>
          <Table
            striped
            bordered
            hover
            size="sm"
            className="table_view_history_popup"
          >
            <thead>
              <tr>
                <th>Edit</th>
                <th>Date</th>
                <th>User</th>
                <th>Status</th>
                <th>Description</th>
                <th>
                  Outdated By Action{" "}
                  <UserManualLink
                    url="/usermanual/qualitycontrol/#outdated-user-validity"
                    testId="um-outdated"
                    className="align-self-start ms-1"
                  />
                </th>
                <th>Outdated By User Validation</th>
              </tr>
            </thead>
            <tbody>
              {userValidations?.getUserValidations?.userValidations?.map(
                (validation: UserValidation) => {
                  const actionId = validation.outdatedByActionHistory?.actionId;
                  const versionId =
                    validation.outdatedByUserValidation?.versionId;

                  return (
                    <tr key={validation.id}>
                      <td>{validation.revision}</td>
                      <td>{validation.created}</td>
                      <td>{validation.createdBy}</td>
                      <td>
                        {getKeyByValue(
                          ValidityStatus,
                          validation.validityStatus
                        )}
                      </td>
                      <td>{validation.description}</td>
                      <td className="outdatedByAction_link">
                        {actionId && (
                          <Link target="_blank" to={`/action/${actionId}`}>
                            {actionId}
                          </Link>
                        )}
                      </td>

                      <td>
                        {versionId && (
                          <Button
                            onClick={() => {
                              getPreviewURL(versionId);
                            }}
                            className="outdatedByUser_button"
                            variant="link"
                          >
                            {versionId}
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </Table>
        </>
      )}
      {(errorValidationQuery || errorCreateUserValidation) && (
        <Error error={errorValidationQuery || errorCreateUserValidation} />
      )}
      <Modal
        show={showSaveAlert}
        onHide={() => setShowSaveAlert(false)}
        backdrop="static"
        centered
        className="popup_modal_warning"
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoadingCreateUserValidation && (
            <div className="center_div">
              <Spinner animation="border" className="spinner_color" />
            </div>
          )}
          <p>
            <span className="red">Important:</span> You are about to manually
            change the Validity Status and thereby overwrite the System Validity
            Status. Please confirm that you are aware of the consequences:
            change of Validity Status of potentially all downstream documents.
            With this, re-evaluation is required if any future changes affect
            the Validity Status: You are in charge to track the upstream quality
            assessment changes and to revise and reenter the User Validity
            Status.
          </p>
          <p>
            You are aware that this action is an exception and thus requires a
            description of the rationale.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowSaveAlert(false)}
            disabled={isLoadingCreateUserValidation}
          >
            I disagree
          </Button>
          <Button
            variant="primary"
            className="button_save_popup"
            onClick={saveUserValidity}
            disabled={isLoadingCreateUserValidation}
          >
            I agree
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
