import { gql, useQuery, useLazyQuery } from "@apollo/client";

export const QUERY_GET_ACTIONS = gql`
  query getActions(
    $pageNumber: Int
    $pageSize: Int
    $activityId: ID
    $createdBefore: String
  ) {
    getActions(
      input: {
        pageNumber: $pageNumber
        pageSize: $pageSize
        createdBefore: $createdBefore
        activityId: $activityId
      }
    ) {
      actions {
        __typename
        id
        actionType
        actionFiles {
          actionFileType
          file {
            name
            revision
            versionId
            validity {
              overallValidityStatus
            }
            repo {
              name
              id
            }
          }
        }
        startDatetime
        sourceFolder
        sourceFiles
        user {
          email
        }
        description
        actionQualityCheckStatus
        pmxActivity {
          id
          trialNumber
          mainRepository {
            id
            name
          }
        }
        actionStatus
        actionSource
        actionStatusDescription
        hasAdditionalFilesLinked
        actionHistory {
          updated
          description
          actionQualityCheckStatus
          userId
        }
        historyLabel {
          name
        }
        additionalDetails
        jobId
      }
      totalCount
    }
  }
`;
export function useGetActionsQuery({
  pageNumber,
  pageSize,
  activityId
}: {
  pageNumber?: number;
  pageSize?: number;
  activityId?: number;
  createdBefore?: string;
}) {
  const {
    data: actions,
    loading: isLoading,
    error,
    refetch: refetchActions
  } = useQuery(QUERY_GET_ACTIONS, {
    variables: {
      pageNumber,
      pageSize,
      activityId
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });

  return {
    actions,
    refetchActions,
    isLoading,
    error
  };
}

export function useGetLastActionQuery() {
  const [
    getLastAction,
    {
      data: action,
      loading: isActionLoading,
      error: errorAction,
      refetch: refetchAction
    }
  ] = useLazyQuery(QUERY_GET_ACTIONS, {
    fetchPolicy: "no-cache"
  });

  return {
    getLastAction,
    action: action?.getActions?.actions[0] || null,
    isActionLoading,
    errorAction,
    refetchAction
  };
}
