import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as React from "react";
import { useContext, useState } from "react";
import { Button, Container, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import Error from "../abstractComponents/error";
import ErrorBoundary from "../abstractComponents/errorBoundary";
import NavBarPmxActivity from "../pmxActivity/navbarPmxActivity";
import { HistoryLabelList } from "./historyLabelList";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { getActivityColumns } from "./columns";
import { useGetActionsQuery } from "../../backend/hooks/action/queryGetActions";

export function HistoryPick() {
  const { activityId, activityData, errorActivity } = useContext(
    PmxActivityDetailsContext
  );
  const [selectedTabId, setSelectedTabId] = useState("1");
  const [selectedDate, setSelectedDate] = useState();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTabId(newValue);
  };

  const { actions, isLoading, error } = useGetActionsQuery({
    activityId: activityId
  });

  const disablePreviousDates = (date: Date) => {
    return (
      new Date(date.toISOString()).getTime() <
      new Date(activityData?.mainRepository?.created).getTime()
    );
  };

  return (
    <>
      <NavBarPmxActivity activeIndex={4} />

      <div className="app_content pmx_app_content">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          className="float-start pmx_bradcrumbs"
        >
          <Link to={`/activity/${activityId}`}>
            {activityData?.trialNumber}
          </Link>
          <Typography>History</Typography>,<Typography>Select Time</Typography>
        </Breadcrumbs>
        <br />
        <hr />
        <ErrorBoundary>
          <Container fluid>
            <TabContext value={selectedTabId}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Actions" data-id="actions_lable_pick" value="1" />
                  <Tab
                    label="History Label"
                    data-id="history_lable_pick"
                    value="2"
                  />
                  <Tab
                    label="Timestamp"
                    data-id="timestamp_lable_pick"
                    value="3"
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                {isLoading ? (
                  <div className="center_div">
                    <Spinner animation="border" className="spinner_color" />
                    <p>Loading...</p>
                  </div>
                ) : actions?.getActions &&
                  actions?.getActions.actions.length > 0 ? (
                  <DataGridPro
                    rows={actions?.getActions.actions}
                    columns={getActivityColumns(activityId)}
                    disableSelectionOnClick
                    getRowId={(row) => row.id}
                    autoHeight
                    pageSize={50}
                  />
                ) : (
                  <div className="no_repositories_available_div">
                    No Actions are accessible
                  </div>
                )}
              </TabPanel>
              <TabPanel value="2">
                <HistoryLabelList />
              </TabPanel>
              <TabPanel value="3">
                <h3>Timestamp Selection</h3>
                <br />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Timestamp"
                    onChange={(date) => {
                      setSelectedDate(date.toISOString());
                    }}
                    disableFuture
                    shouldDisableDate={disablePreviousDates}
                  />
                </LocalizationProvider>
                <br />
                <br />
                <Link
                  to={`/activity/${activityId}/history/fileExplorer?historicalDate=${selectedDate}`}
                >
                  <Button disabled={!selectedDate}>View Historical Data</Button>
                </Link>
              </TabPanel>
            </TabContext>
          </Container>
        </ErrorBoundary>
      </div>

      {(errorActivity || error) && <Error error={errorActivity || error} />}
    </>
  );
}
