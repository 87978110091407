import { Container, Modal, Spinner } from "react-bootstrap";
import { RepoObject } from "../../backend/types";

import { useGetTraceabilityReport } from "../../backend/hooks/queryGetTracebilityReport";
import Error from "../abstractComponents/error";
import ActionTable from "../abstractComponents/actionTable";
import UserManualLink from "../abstractComponents/usermanual";

// Component dealing with the tracebility report that can be accessed from Table Veiw -> Select a file -> Tracebility Report
export default function TracebilityReport(props: {
  activityId: number;
  onNodeSelect: Function;
  onClose: Function;
  currentFile: RepoObject;
}) {
  const refetchAllActions = () => {
    refetchTracebilityActions();
  };

  const {
    tracebilityActions,
    isLoadingTracebilityActions,
    errorTracebilityActions,
    refetchTracebilityActions
  } = useGetTraceabilityReport(props.currentFile?.versionId, props.activityId);

  return (
    <>
      <Modal
        show
        enforceFocus={false}
        onHide={() => props.onClose()}
        dialogClassName="modal-80w"
        aria-labelledby="custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="custom-modal-styling-title">
            Traceability Report Of &quot;{props.currentFile?.name}&quot;
          </Modal.Title>
          <UserManualLink
            url="/usermanual/traceability/"
            testId="um-traceability"
            className="align-self-start ms-1"
          />
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            {isLoadingTracebilityActions ? (
              <div className="center_div">
                <Spinner animation="border" className="spinner_color" />
                <p>Loading...</p>
              </div>
            ) : (
              <>
                {!tracebilityActions ||
                !tracebilityActions.getTraceabilityActions.actions.length ? (
                  <>
                    <br />
                    <br />
                    <p>No actions</p>
                  </>
                ) : (
                  <>
                    <div className="list_repositories_container">
                      <ActionTable
                        rowsActions={
                          tracebilityActions.getTraceabilityActions.actions
                        }
                        refetchActions={refetchAllActions}
                        onNodeSelect={props.onNodeSelect}
                        areFilesSplit={true}
                        isExportEnabled={true}
                        onClose={props.onClose}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </Container>
        </Modal.Body>
      </Modal>
      {errorTracebilityActions && <Error error={errorTracebilityActions} />}
    </>
  );
}
