import DescriptionIcon from "@mui/icons-material/Description";
import FolderIcon from "@mui/icons-material/Folder";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InventoryIcon from "@mui/icons-material/Inventory";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import LocalMoviesIcon from "@mui/icons-material/LocalMovies";
import InstallDesktopIcon from "@mui/icons-material/InstallDesktop";
import {
  compressedFileExtensions,
  excelFormatsExtensions,
  imageExtensions,
  installersExtensions,
  isFileEndsWith,
  moviesExtensions
} from "../../helpers/fileHelper";
import { ValidityStatus } from "../../backend/types";

const generateValidityClassName = (
  validityStatus: ValidityStatus | null,
  className: string
) => {
  switch (validityStatus) {
    case ValidityStatus.Valid:
      return "is_valid_clean main_icon " + className;
    case ValidityStatus.Invalid:
      return "is_invalid main_icon " + className;
    case ValidityStatus.NotSet:
      return "grey_color main_icon " + className;
    default:
      return "grey_color main_icon " + className;
  }
};
// Redering an icon of a file
const FileIcon = (props: {
  fileName: string;
  overallValidityStatus: ValidityStatus | null;
  fontSize?: "small" | "inherit" | "large" | "medium" | undefined;
  className?: string | undefined;
  absolutePath?: string | undefined;
}) => (
  <>
    {props.fileName.endsWith("/") ||
    (props.absolutePath && props.absolutePath.endsWith("/")) ? (
      <FolderIcon
        className="grey_color folder_icon main_icon"
        fontSize={props.fontSize}
      />
    ) : props.fileName.toLocaleLowerCase().endsWith(".pdf") ? (
      <PictureAsPdfIcon
        className={generateValidityClassName(
          props.overallValidityStatus,
          `${props.className} pdf_icon`
        )}
        fontSize={props.fontSize}
      />
    ) : isFileEndsWith(
        props.fileName.toLocaleLowerCase(),
        compressedFileExtensions
      ) ? (
      <InventoryIcon
        className={generateValidityClassName(
          props.overallValidityStatus,
          `${props.className} main_icon`
        )}
        fontSize={props.fontSize}
      />
    ) : isFileEndsWith(props.fileName.toLocaleLowerCase(), imageExtensions) ? (
      <InsertPhotoIcon
        className={generateValidityClassName(
          props.overallValidityStatus,
          `${props.className} photo_icon`
        )}
        fontSize={props.fontSize}
      />
    ) : props.fileName.toLocaleLowerCase().endsWith(".ppt") ||
      props.fileName.toLocaleLowerCase().endsWith(".pptx") ? (
      <SlideshowIcon
        className={generateValidityClassName(
          props.overallValidityStatus,
          `${props.className} main_icon`
        )}
        fontSize={props.fontSize}
      />
    ) : isFileEndsWith(
        props.fileName.toLocaleLowerCase(),
        excelFormatsExtensions
      ) ? (
      <CalendarViewMonthIcon
        className={generateValidityClassName(
          props.overallValidityStatus,
          `${props.className} main_icon`
        )}
        fontSize={props.fontSize}
      />
    ) : props.fileName.toLocaleLowerCase().endsWith(".txt") ||
      props.fileName.toLocaleLowerCase().endsWith(".doc") ||
      props.fileName.toLocaleLowerCase().endsWith(".log") ||
      props.fileName.toLocaleLowerCase().endsWith(".docx") ? (
      <ArticleOutlinedIcon
        className={generateValidityClassName(
          props.overallValidityStatus,
          props.className ?? ""
        )}
        fontSize={props.fontSize}
      />
    ) : isFileEndsWith(props.fileName.toLocaleLowerCase(), moviesExtensions) ? (
      <LocalMoviesIcon
        className={generateValidityClassName(
          props.overallValidityStatus,
          props.className ?? ""
        )}
        fontSize={props.fontSize}
      />
    ) : isFileEndsWith(
        props.fileName.toLocaleLowerCase(),
        installersExtensions
      ) ? (
      <InstallDesktopIcon
        className={generateValidityClassName(
          props.overallValidityStatus,
          props.className ?? ""
        )}
        fontSize={props.fontSize}
      />
    ) : (
      <DescriptionIcon
        className={generateValidityClassName(
          props.overallValidityStatus,
          props.className ?? ""
        )}
        fontSize={props.fontSize}
      />
    )}
  </>
);

export default FileIcon;
