import { gql, useQuery } from "@apollo/client";
import { Maybe } from "../types";

export const GET_TRACEBILITY_REPORT = gql`
  query getTraceabilityActions($versionId: ID!) {
    getTraceabilityActions(input: { versionId: $versionId }) {
      actions {
        __typename
        id
        actionType
        actionFiles {
          __typename
          actionFileType
          file {
            __typename
            name
            versionId
            revision
            validity {
              overallValidityStatus
            }
            repo {
              name
              id
            }
          }
        }
        startDatetime
        sourceFolder
        sourceFiles
        user {
          email
        }
        description
        actionQualityCheckStatus
        pmxActivity {
          __typename
          id
          trialNumber
          mainRepository {
            id
            name
          }
        }
        actionStatus
        actionSource
        actionStatusDescription
        actionHistory {
          __typename
          updated
          description
          actionQualityCheckStatus
          userId
        }
        historyLabel {
          __typename
          id
          name
        }
        hasAdditionalFilesLinked
        additionalDetails
        jobId
      }
    }
  }
`;

export function useGetTraceabilityReport(
  versionId: Maybe<string> | undefined,
  activityId: number
) {
  const {
    data: tracebilityActions,
    loading: isLoadingTracebilityActions,
    error: errorTracebilityActions,
    refetch: refetchTracebilityActions
  } = useQuery(GET_TRACEBILITY_REPORT, {
    variables: { versionId, activityId },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  }); // TODO: Query results is not stored in the cache to avoid a mix up in actionFile

  return {
    refetchTracebilityActions,
    tracebilityActions,
    isLoadingTracebilityActions,
    errorTracebilityActions
  };
}
