import { DataGridPro } from "@mui/x-data-grid-pro";
import { Link, useParams } from "react-router-dom";
import { useGetHistoryLabels } from "../../backend/hooks/history/queryGetHistoryLabels";
import { useState } from "react";

export function HistoryLabelList() {
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const { activityId } = useParams<{
    activityId: string;
  }>();
  const { historyLabels, getHistoryLabelsLoading, getHistoryLabelsError } =
    useGetHistoryLabels(Number(activityId), page, pageSize);

  const COLUMNS = [
    {
      field: "name",
      headerName: "History Label",
      sortable: false,
      filterable: false,
      flex: 1.5,
      renderCell: (params: any) => (
        <div key={params.row.id}>
          <Link
            className="history_labels_link"
            data-testid={"history_label_name"}
            to={`/activity/${activityId}/history/fileExplorer?actionId=${params.row.actionId}`}
          >
            {params.row.name}
          </Link>
        </div>
      )
    },
    {
      field: "updatedBy",
      headerName: "User",
      flex: 1,
      sortable: false,
      filterable: false
    },
    {
      field: "actionId",
      headerName: "Action ID",
      flex: 1,
      sortable: false,
      filterable: false
    }
  ];

  return (
    <>
      <DataGridPro
        rows={historyLabels ? historyLabels.historyLabels : []}
        rowCount={historyLabels ? historyLabels.totalCount : 0}
        columns={COLUMNS}
        autoHeight
        hideFooterSelectedRowCount
        page={page}
        pageSize={pageSize}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
        pagination
        paginationMode={"server"}
        rowsPerPageOptions={[10, 15, 100]}
        loading={getHistoryLabelsLoading}
        error={getHistoryLabelsError}
      />
    </>
  );
}
