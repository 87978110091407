import { ActionType as ActionTypeBackend } from "../backend/types";
import { ActionTypeUI } from "../backend/types";

export function checkIfActionIsNotEditable(
  currentAction: ActionTypeBackend
): boolean {
  return [
    ActionTypeUI.DOWNLOAD,
    ActionTypeUI.ARCHIVE,
    ActionTypeUI.UNCOMPRESS,
    ActionTypeUI.RENAME,
    ActionTypeUI.RESTORE,
    ActionTypeUI.COPY,
    ActionTypeUI.MOVE
  ].includes(ActionTypeUI[currentAction]);
}
