import MaterialButton from "@mui/material/Button";
import { DataGridPro, GridRowModel } from "@mui/x-data-grid-pro";
import FileIcon from "../../components/abstractComponents/fileIcon";
import FolderIcon from "@mui/icons-material/Folder";
import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";
import { useEffect, useState, useContext } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  NavDropdown
} from "react-bootstrap";
import { useImportSwanContentMutation } from "../../backend/hooks/importExportMenu/mutationImportSwanContent";
import { useGetSwanContentQuery } from "../../backend/hooks/importExportMenu/queryGetSwanContent";
import {
  encryptStringWithRsaPublicKey,
  MAXIMUM_CLEAR_PASSWORD,
  getQualityCheckStatusKeyFromValue
} from "../../helpers/stringHelper";
import Error from "../abstractComponents/error";
import Success from "../abstractComponents/success";
import {
  ActionStatus,
  SwanFile,
  ValidityStatus,
  QualityCheckStatusUI,
  QualityCheckStatusType
} from "../../backend/types";
import { formatFileSize } from "../../helpers/stringHelper";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { customFilters } from "../../helpers/tableViewHelper";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";

// Component that renders everything related to SWAN Import
export default function ImportFromSwan(props: {
  currentFolder: string;
  disabled: boolean;
  refetchRepoObjects: Function;
  currentSubArray: [any];
}) {
  const { activityId, activityData } = useContext(PmxActivityDetailsContext);
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 4,
      filterOperators: customFilters,
      renderCell: (params: any) => {
        let weHaveSameName = false;
        props.currentSubArray?.forEach((element: any) => {
          if (element.name === params.row.name) {
            weHaveSameName = true;
          }
        });
        return (
          <div key={params.row.name} style={{ width: "100%" }}>
            {params.row.isDir ? (
              <MaterialButton
                variant="text"
                onClick={() => onClickOnFileOrFolder(params.row.id)}
                className="button_without_text_transform float-start"
              >
                <>
                  <FolderIcon
                    className="grey_color folder_icon"
                    style={{ fontSize: 50 }}
                  />
                  <span className="folder_name_table_view_span">
                    {params.row.name}
                  </span>
                </>
              </MaterialButton>
            ) : (
              <>
                <FileIcon
                  fileName={params.row.name}
                  overallValidityStatus={ValidityStatus.NotSet}
                  fontSize={"large"}
                  className={weHaveSameName ? "warning_clean" : ""}
                />
                {params.row.name + (weHaveSameName ? " (name exists)" : "")}
              </>
            )}
          </div>
        );
      },
      valueGetter: (params: any) => params.row.name
    },
    {
      field: "size",
      headerName: "Size",
      flex: 2,
      filterOperators: customFilters,
      valueGetter: (params: any) => params.row.size,
      renderCell: (params: any) =>
        params.row.isDir ? "" : formatFileSize(params.row.size),
      sortComparator: (v1: any, v2: any, param1: any, param2: any) =>
        (param1.api.getCellValue(param1.id, "size") as number) -
        (param2.api.getCellValue(param2.id, "size") as number)
    }
  ];

  const defaultRootAddressSwanImport =
    localStorage.getItem("defaultRootAddressSwanImport") &&
    localStorage.getItem("defaultRootAddressSwanImport") !== "undefined" &&
    localStorage.getItem("defaultRootAddressSwanImport") !== ""
      ? String(localStorage.getItem("defaultRootAddressSwanImport"))
      : "/var/swan/root/bhc/";
  const [rootAddress, setRootAddress] = useState(defaultRootAddressSwanImport);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [weHaveSameNameSomewhere, setWeHaveSameNameSomewhere] = useState(false);
  const [qualityCheckStatus, setQualityCheckStatus] =
    useState<QualityCheckStatusUI>(QualityCheckStatusUI.NOT_STARTED);
  const [description, setDescription] = useState("");
  const [swanUsername, setSwanUsername] = useState(
    localStorage.getItem("swanUsername") || ""
  );
  const [swanPassword, setSwanPassword] = useState(
    localStorage.getItem("swanPassword") || ""
  );
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  useEffect(() => {
    setWeHaveSameNameSomewhere(false);
    props.currentSubArray?.forEach((element: any) => {
      selectedRows.forEach((selectedElement: string) => {
        if (element.name === selectedElement.split("/").slice(-1)[0]) {
          setWeHaveSameNameSomewhere(true);
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);
  // We actually need the effect to trigger only when the popup is shown, so we donät put more stress on the server
  useEffect(() => {
    if (isImportModalVisible && rootAddress && swanUsername && swanPassword) {
      handleLoadSwanFolder(rootAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isImportModalVisible]);

  const { getSwanContent, error, loading, data } = useGetSwanContentQuery();

  const handleLoadSwanFolder = (absolutePath: string, password?: string) => {
    getSwanContent({
      variables: {
        swanUser: swanUsername,
        encryptedPassword: password ? password : swanPassword,
        absolutePath
      }
    });
  };

  const onClickOnFileOrFolder = (address: string) => {
    setRootAddress(address);
    fetchSwanData();
    localStorage.setItem("defaultRootAddressSwanImport", address);
    handleLoadSwanFolder(address);
  };

  const clickOnBack = () => {
    const newAddress = rootAddress.split("/");
    newAddress.pop();
    newAddress.pop();
    const newAddressString = newAddress.join("/") + "/";
    setRootAddress(newAddressString);

    localStorage.setItem("defaultRootAddressSwanImport", newAddressString);
    handleLoadSwanFolder(newAddressString);
  };

  const fetchSwanData = () => {
    localStorage.setItem("defaultRootAddressSwanImport", rootAddress);
    if (
      swanPassword.length > MAXIMUM_CLEAR_PASSWORD &&
      swanPassword.endsWith("=")
    ) {
      handleLoadSwanFolder(rootAddress);
    } else {
      //Password is not encrypted, so we encrypt it
      const newPassword = encryptStringWithRsaPublicKey(swanPassword);
      setSwanPassword(newPassword);
      localStorage.setItem("swanUsername", swanUsername);
      localStorage.setItem("swanPassword", newPassword);
      handleLoadSwanFolder(rootAddress, newPassword);
    }
  };

  const handleSelectMultipleRows = (e: any) => {
    setSelectedRows(e);
  };

  const {
    importContentMutation,
    swanImportMutationData,
    isLoadingUploadMutation,
    errorUploadMutation
  } = useImportSwanContentMutation();

  const closeModal = () => {
    setIsImportModalVisible(false);
    setQualityCheckStatus(QualityCheckStatusUI.NOT_STARTED);
    setSelectedRows([]);
    setDescription("");
  };

  function changeQualityCheckedStatus(
    event: React.ChangeEvent<HTMLSelectElement>
  ) {
    event.preventDefault();
    setQualityCheckStatus(
      QualityCheckStatusUI[event.target.value as QualityCheckStatusType]
    );
  }

  const importAll = () => {
    if (
      weHaveSameNameSomewhere &&
      !window.confirm("Are you sure that you want to overwrite files?")
    ) {
      return;
    }

    importContentMutation({
      variables: {
        swanUser: swanUsername,
        encryptedPassword: swanPassword,
        absoluteSwanPaths: selectedRows,
        parentFolder: props.currentFolder,
        activityId: activityId,
        qualityCheckStatus:
          getQualityCheckStatusKeyFromValue(qualityCheckStatus),
        description: description
      }
    }).then(() => {
      if (!error) {
        closeModal();
        props.refetchRepoObjects();
      }
    });
  };

  useEffect(() => {
    if (
      String(errorUploadMutation).includes("decrypting password") ||
      String(error).includes("decrypting password")
    ) {
      localStorage.removeItem("swanPassword");
      setSwanPassword("");
    }
  }, [errorUploadMutation, error]);

  return (
    <>
      <NavDropdown.Item
        size="small"
        variant="outlined"
        onClick={() => {
          setIsImportModalVisible(true);
        }}
        disabled={props.disabled}
        id="importFromSwanItem"
      >
        <ImportantDevicesIcon /> SWAN Import
      </NavDropdown.Item>

      {swanImportMutationData?.importSwanContent?.actionStatus ===
        ActionStatus.Pending && (
        <Success message="SWAN import started. It can take up to 15 Minutes." />
      )}
      {(error ||
        errorUploadMutation ||
        swanImportMutationData?.importSwanContent?.actionStatus ===
          "Error") && <Error error={error || errorUploadMutation} />}
      <Modal
        show={isImportModalVisible}
        onHide={closeModal}
        dialogClassName="modal-80w"
        aria-labelledby="importFromSwanTitle"
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="importFromSwanTitle">
            Import from SWAN into &quot;{activityData.trialNumber}/
            {props.currentFolder}&quot;
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row
              className={
                swanPassword.length > MAXIMUM_CLEAR_PASSWORD
                  ? "login_grey_color_row"
                  : ""
              }
            >
              <Col xs={2}>
                <Form.Label>SWAN Username:</Form.Label>
              </Col>
              <Col xs={3}>
                <Form.Control
                  type="text"
                  required
                  placeholder="Enter username"
                  id="importFromSwanUserFormControl"
                  value={swanUsername}
                  onChange={(e) => setSwanUsername(e.target.value)}
                />
              </Col>
            </Row>
            <Row
              className={
                swanPassword.length > MAXIMUM_CLEAR_PASSWORD
                  ? "login_grey_color_row"
                  : ""
              }
            >
              <Col xs={2}>
                <Form.Label>SWAN Password:</Form.Label>
              </Col>
              <Col xs={3}>
                <Form.Control
                  type="password"
                  required
                  placeholder="Enter password"
                  id="importFromSwanPasswordFormControl"
                  value={swanPassword}
                  onChange={(e) => setSwanPassword(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={2}>
                <Form.Label>SWAN folder:</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  required
                  placeholder="Enter SWAN folder"
                  id="importFromSwanRootAddressFormControl"
                  value={rootAddress}
                  onChange={(e) => setRootAddress(e.target.value)}
                />
              </Col>
              <Col xs={2}>
                <Button
                  variant="primary"
                  id="getSwanContentButton"
                  disabled={
                    !rootAddress.endsWith("/") || !swanUsername || !swanPassword
                  }
                  onClick={fetchSwanData}
                >
                  {rootAddress.endsWith("/") ? "Open folder" : "Not a folder"}
                </Button>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col xs={2}>
                <Form.Label>Import Description:</Form.Label>
              </Col>
              <Col xs={3}>
                <Form.Control
                  type="text"
                  required
                  placeholder="Enter description"
                  id="importFromSwanDescriptionFormControl"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={2}>
                <Form.Label>Quality Control Status</Form.Label>
              </Col>
              <Col xs={3}>
                <Form.Select
                  value={String(
                    getQualityCheckStatusKeyFromValue(qualityCheckStatus)
                  )}
                  onChange={changeQualityCheckedStatus}
                  key="swan_quality_controlled"
                  id="importFromSwanQualityFormSelect"
                >
                  {Object.entries(QualityCheckStatusUI).map((element) => (
                    <option key={element[0]} value={element[0]}>
                      {element[1]}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
            <br />
            <br />

            {loading || isLoadingUploadMutation ? (
              <div className="center_div">
                <Spinner animation="border" className="spinner_color" />
                <p>Loading...</p>
              </div>
            ) : (
              data && (
                <>
                  <Row>
                    <Col xs={2}>
                      <Button variant="light" onClick={clickOnBack}>
                        <ArrowBackIcon className="w-auto align-self-center" />{" "}
                        Back
                      </Button>
                    </Col>
                    <Col xs={3}>
                      <Button
                        variant="primary"
                        disabled={selectedRows.length === 0}
                        onClick={importAll}
                        id="finishImportFromSwanButton"
                      >
                        Import Selected File
                        {selectedRows.length > 1 &&
                          "s (" + selectedRows.length + ")"}
                      </Button>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <DataGridPro
                      rows={
                        data?.getSwanContent
                          ? (data?.getSwanContent.map((swanFile: SwanFile) => ({
                              id: rootAddress + swanFile.name,
                              name: swanFile.name.endsWith("/")
                                ? swanFile.name.split("/").reverse()[1]
                                : swanFile.name.split("/").reverse()[0],
                              isDir: swanFile.name.endsWith("/"),
                              size: swanFile.size
                            })) as readonly GridRowModel[])
                          : []
                      }
                      columns={columns}
                      pageSize={50}
                      rowHeight={60}
                      autoHeight
                      disableSelectionOnClick
                      checkboxSelection
                      className="browser_repository_table"
                      selectionModel={selectedRows}
                      onSelectionModelChange={handleSelectMultipleRows}
                    />
                  </Row>
                </>
              )
            )}
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}
