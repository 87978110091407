import { gql, useQuery } from "@apollo/client";
export const GET_MY_REPO_OBJECTS_FOR_VERSION_ID_QUERY = gql`
  query getRepoObject($versionId: ID!) {
    getRepoObject(input: { versionId: $versionId }) {
      __typename
      name
      repo {
        mainActivityId
        id
      }
    }
  }
`;

export function useGetRepoObject(versionId: string) {
  const {
    data: repoObject,
    loading: isRepoObjectLoading,
    error: repoObjectError
  } = useQuery(GET_MY_REPO_OBJECTS_FOR_VERSION_ID_QUERY, {
    variables: {
      versionId: versionId
    }
  });

  return {
    repoObject,
    isRepoObjectLoading,
    repoObjectError
  };
}
