import { useQuery } from "@apollo/client";
import { useContext, useState, useEffect } from "react";
import { GET_DOWNLOAD_LINKS } from "../../backend/hooks/importExportMenu/queryGetDownloadLinks";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import Error from "../abstractComponents/error";
import { Spinner } from "react-bootstrap";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";

interface PdfPreviewProps {
  absolutePath: string;
  versionId: string;
  repoId: string;
}

export function ComplexFilePreview(props: PdfPreviewProps) {
  const { activityData } = useContext(PmxActivityDetailsContext);
  const [htmlContent, setHtmlContent] = useState("");

  const {
    error: errorDownload,
    data: downloadUrls,
    loading
  } = useQuery(GET_DOWNLOAD_LINKS, {
    variables: {
      absolutePaths: [props.absolutePath],
      activityId: Number(activityData.id),
      versionIds: props.versionId ? [props.versionId] : [],
      repoId: props.repoId
    }
  });
  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar } = toolbarPluginInstance;

  useEffect(() => {
    async function readFileFromURL(url: string) {
      try {
        const response = await fetch(url);
        const text = await response.text();
        setHtmlContent(text);
      } catch (error) {
        console.error("Error fetching the file:", error);
      }
    }
    const fileURL = downloadUrls?.getDownloadLinks?.presignedUrls[0].url;
    if (!props.absolutePath.endsWith(".pdf")) {
      readFileFromURL(fileURL);
    }
  }, [downloadUrls]);

  return (
    <>
      {loading && (
        <div className="center_div">
          <Spinner animation="border" className="spinner_color" />
          <p>Loading...</p>
        </div>
      )}
      {props.absolutePath.endsWith(".pdf") ? (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.9.179/build/pdf.worker.min.js">
          <div className="pdf_preview">
            {downloadUrls?.getDownloadLinks?.presignedUrls[0].url && (
              // style here is as provided by documentation
              <div
                className="rpv-core__viewer"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%"
                }}
              >
                <div
                  style={{
                    alignItems: "center",
                    backgroundColor: "#eeeeee",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                    display: "flex",
                    padding: "4px"
                  }}
                >
                  <Toolbar />
                </div>
                <div
                  style={{
                    flex: 1,
                    overflow: "hidden"
                  }}
                >
                  <Viewer
                    fileUrl={
                      downloadUrls?.getDownloadLinks?.presignedUrls[0].url
                    }
                    plugins={[toolbarPluginInstance]}
                  />
                </div>
              </div>
            )}
          </div>
        </Worker>
      ) : (
        <iframe srcDoc={htmlContent} width={1000} height={600}></iframe>
      )}

      {errorDownload && <Error error={errorDownload} />}
    </>
  );
}
