import { gql, useLazyQuery } from "@apollo/client";
export const GET_MY_REPO_OBJECTS_FOR_PATH_QUERY = gql`
  query getRepoObjectsForPath(
    $path: String!
    $repoId: Int!
    $pmxActivityId: Int!
  ) {
    getRepoObjectsForPath(
      input: { path: $path, repoId: $repoId, pmxActivityId: $pmxActivityId }
    ) {
      __typename
      name
      isDir
      created
      createdBy
      versionId
      revision
      md5
      size
      comments {
        id
        text
        created
        createdBy
      }
      validity {
        qualityCheckStatus
        overallValidityStatus
        userValidityStatus
        systemValidityStatus
        outdatedUserValidityStatus
      }
    }
  }
`;

export function useGetRepoObjectsForPath(
  currentPath: String,
  repoId: Number,
  pmxActivityId: Number
) {
  const [
    getRepoObjects,
    {
      data: repoObjects,
      loading: areRepoObjectsLoading,
      error: repoObjectsError,
      refetch: refetchRepoObjectsForPath
    }
  ] = useLazyQuery(GET_MY_REPO_OBJECTS_FOR_PATH_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    variables: {
      path: currentPath,
      repoId: repoId,
      pmxActivityId: pmxActivityId
    }
  });

  return {
    getRepoObjects,
    repoObjects,
    areRepoObjectsLoading,
    repoObjectsError,
    refetchRepoObjectsForPath: () => {
      return new Promise(function (resolve: Function) {
        setTimeout(() => {
          refetchRepoObjectsForPath();
        }, 3500); // 3s is the time it takes the s3 lambda function to run cold
        resolve();
      });
    }
  };
}
