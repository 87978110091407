import {
  Col,
  Container,
  Modal,
  Row,
  Spinner,
  Form,
  Button,
  NavDropdown
} from "react-bootstrap";
import Error from "../abstractComponents/error";
import { useState, useEffect } from "react";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import { useEditHistoryLabelMutation } from "../../backend/hooks/history/mutationEditHistoryLabel";
import { useGetLastActionQuery } from "../../backend/hooks/action/queryGetActions";
import Success from "../abstractComponents/success";
import { useGetActionQuery } from "../../backend/hooks/action/queryGetAction";

// Create Download Link, that can be used by external Users
export default function CreateHistoryLabel(props: {
  activityId: number;
  actionId?: number | null;
  onComplete?: Function;
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [historyLabel, setHistoryLabel] = useState("");
  const [isSuccessVisible, setIsSuccessVisible] = useState(false);

  const {
    editHistoryLabelMutation,
    isLoadingEditHistoryLabelMutation,
    errorEditHistoryLabelMutation
  } = useEditHistoryLabelMutation();

  const { getLastAction, action, isActionLoading, errorAction } =
    useGetLastActionQuery();

  const {
    getAction,
    action: actionPulled,
    isActionLoading: isActionLoadingPulled,
    errorAction: errorActionPulled
  } = useGetActionQuery();

  const submitData = () => {
    editHistoryLabelMutation({
      variables: {
        actionId: props.actionId || action?.id,
        historyLabel: historyLabel
      }
    }).then(() => {
      setIsModalVisible(false);
      setIsSuccessVisible(true);
      if (props.onComplete) {
        props.onComplete();
      }
    });
  };

  useEffect(() => {
    if (!props.actionId) {
      getLastAction({
        variables: {
          activityId: props.activityId,
          createdBefore: new Date().toISOString()
        }
      });
    } else {
      getAction({
        variables: {
          actionId: props.actionId
        }
      });
    }
  }, [props.actionId]);

  useEffect(() => {
    if (action && action?.name && historyLabel === "") {
      setHistoryLabel(String(action?.name));
    }
  }, [action]);

  useEffect(() => {
    if (
      actionPulled &&
      actionPulled?.getAction?.historyLabel?.name &&
      historyLabel === ""
    ) {
      setHistoryLabel(String(actionPulled?.getAction?.historyLabel?.name));
    }
  }, [actionPulled]);

  return (
    <>
      <NavDropdown.Item
        data-testid="createHistoryLabel"
        onClick={() => {
          setIsModalVisible(true);
        }}
        id="createHistoryLabel"
        className="nav-link nav_item_button"
        disabled={!props.actionId && !action?.id}
      >
        <HistoryToggleOffIcon /> {historyLabel === "" ? "New" : "Rename"}{" "}
        History Label
      </NavDropdown.Item>

      <Modal
        show={isModalVisible}
        onHide={() => {
          setIsModalVisible(false);
        }}
        dialogClassName="modal-75w"
        aria-labelledby="createHisoryLabel"
      >
        <Modal.Header closeButton={!isLoadingEditHistoryLabelMutation}>
          <Modal.Title id="createHisoryLabel">
            Create History Label for action ID {props.actionId || action?.id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            {isLoadingEditHistoryLabelMutation ||
            isActionLoading ||
            isActionLoadingPulled ? (
              <div className="center_div">
                <Spinner animation="border" className="spinner_color" />
                <p>Loading...</p>
              </div>
            ) : (
              <Form>
                <Row>
                  <Col xs={8}>
                    <Form.Label>History Label</Form.Label>
                    <Form.Control
                      type="email"
                      required
                      data-testid="createHistoryLabelName"
                      placeholder="Enter a history label"
                      value={historyLabel}
                      onChange={(e) => setHistoryLabel(e.target.value)}
                      id="createHistoryLabelName"
                    />
                  </Col>{" "}
                </Row>
                <br />
                <Row>
                  <Col>
                    <Button
                      className="float-end"
                      variant="primary"
                      disabled={!historyLabel}
                      id="createLabelButton"
                      type="submit"
                      onClick={() => {
                        submitData();
                      }}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Container>
        </Modal.Body>
      </Modal>
      {(errorEditHistoryLabelMutation || errorAction || errorActionPulled) && (
        <Error
          error={
            errorEditHistoryLabelMutation || errorAction || errorActionPulled
          }
        />
      )}
      {isSuccessVisible && <Success message="History Label Created" />}
    </>
  );
}
