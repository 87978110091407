import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useCreateFolderMutation } from "../backend/hooks/importExportMenu/mutationCreateFolder";
import { useGetRepoObjectsQuery } from "../backend/hooks/pmxActivity/queryGetRepoObjects";
import { useHistoryVersionsLazyQuery } from "../backend/hooks/contextMenu/queryGetHistoryVersions";
import { GetRepoObjectQueryData } from "../backend/types";
import {
  extractIdAndPathFromParameter,
  COMMENT_ID_LABEL
} from "../helpers/urlHelper";
import { BrowsePmxActivityContext } from "../contexts/browsePmxActivityProvider";
import getTreeHierarchyFromRepoObjectList from "../helpers/treeHelper";
import { useHistory } from "react-router-dom";
import { useGetRepoObjectsForPath } from "../backend/hooks/pmxActivity/queryGetRepoObjectsForPath";

interface ParamsActivity {
  activityId: string;
}

// This hooked is reused in multiple places, and needed for the functions related to browsing the repository
export function useBrowseRepositoryHook() {
  const { setTreeHierarchyGlobal } = useContext(BrowsePmxActivityContext);
  const {
    activityId,
    path,
    versionId,
    openProperties = false,
    repoId,
    commentId
  } = extractIdAndPathFromParameter((useParams() as ParamsActivity).activityId);

  let selectedCommentIdFromMenuFromURL = null;
  if (window.location.href.split("#" + COMMENT_ID_LABEL).length > 1) {
    selectedCommentIdFromMenuFromURL = Number(
      window.location.href.split("#" + COMMENT_ID_LABEL)[1]
    );
  }

  if (commentId) {
    selectedCommentIdFromMenuFromURL = Number(commentId);
  }

  const [treeHierarchy, setTreeHierarchy] = useState<
    GetRepoObjectQueryData[] | []
  >([]);

  // This is not split up, because we need to set the entire thing on a back button, and the hook to only to trigger once
  const [currentSelectedState, setCurrentSelectedState] = useState({
    currentSelectedPath: path ? path : "/",
    currentSelectedVersionId: versionId ? versionId : "",
    currentSelectedRepoId: repoId ? repoId : null,
    currentSelectedCommentId: commentId ? commentId : null
  });

  const setCurrentSelectedPath = (path: string) => {
    setCurrentSelectedState((prevstate) => ({
      ...prevstate,
      currentSelectedPath: path
    }));
  };
  const setCurrentSelectedVersionId = (versionId: string) => {
    setCurrentSelectedState((prevstate) => ({
      ...prevstate,
      currentSelectedVersionId: versionId
    }));
  };
  const setCurrentSelectedRepoId = (repoId: number | null) => {
    setCurrentSelectedState((prevstate) => ({
      ...prevstate,
      currentSelectedRepoId: repoId
    }));
  };

  const [currentSelectedRevision, setCurrentSelectedRevision] = useState("");
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isForceRefreshRunning, setIsForceRefreshRunning] = useState(false);
  const [isCreateFolderWindowOpen, setIsCreateFolderWindowOpen] =
    useState(false);
  const [isActionOfFilesVisible, setIsActionOfFilesVisible] = useState(false);
  const [isEditActionVisible, setIsEditActionVisible] = useState(false);
  const [isTracebilityReportVisible, setIsTracebilityReportVisible] =
    useState(false);
  const [tableMenuWasOpenOnPath, setTableMenuWasOpenOnPath] = useState("");
  const [selectedCommentIdFromMenu, setSelectedCommentIdFromMenu] = useState<
    null | number
  >(selectedCommentIdFromMenuFromURL);
  const [isProcessingExport, setIsProcessingExport] = useState(false);
  const [openPropertiesModal, setOpenPropertiesModal] =
    useState<boolean>(false);
  const [isCopyMoveModalOpenHook, setIsCopyMoveModalOpenHook] = useState(false);
  const [isPreselectedRevision, setIsPreselectedRevision] =
    useState<boolean>(false);
  //we set a default GetRepoObjectQueryData, before the data loads
  const [currentFileList, setCurrentFileList] =
    useState<GetRepoObjectQueryData>({
      repoObjects: [],
      isReadOnlyRepository: true,
      repo: {
        id: repoId?.toString() || "",
        name: "",
        isStandard: true
      }
    });

  const {
    getRepoObjects,
    repoObjects,
    repoObjectsError,
    refetchRepoObjectsForPath,
    areRepoObjectsLoading
  } = useGetRepoObjectsForPath(
    currentSelectedState.currentSelectedPath,
    Number(currentSelectedState.currentSelectedRepoId),
    activityId
  );

  const { files, isLoading, dataForceRefresh, error, refetchRepoObjects } =
    useGetRepoObjectsQuery(activityId, forceRefresh, (data: any) => {
      const tree = getTreeHierarchyFromRepoObjectList(data);
      setTreeHierarchyGlobal(tree);
      setTreeHierarchy(tree);
    });

  const { getFileVersions } = useHistoryVersionsLazyQuery();

  useEffect(() => {
    if (forceRefresh) {
      setForceRefresh(false);
      refetchRepoObjectsForPath();
    }
  }, [forceRefresh]);

  useEffect(() => {
    // After Force Refresh ran, we refetch repo objects if we are in a folder
    if (!isForceRefreshRunning && path && path.endsWith("/")) {
      refetchRepoObjectsForPath();
    }
  }, [isForceRefreshRunning]);

  useEffect(() => {
    //we change the URL on every file change
    let currentVersionId: string | undefined;
    if (
      currentSelectedState.currentSelectedVersionId &&
      currentSelectedState.currentSelectedVersionId !== ""
    ) {
      currentVersionId = currentSelectedState.currentSelectedVersionId;
    } else {
      const { versionId } = extractIdAndPathFromParameter(window.location.href);
      currentVersionId = versionId;
    }

    let mergedPath = "/activity/" + activityId;

    if (
      currentSelectedState.currentSelectedPath &&
      currentSelectedState.currentSelectedPath !== "/"
    ) {
      mergedPath =
        mergedPath +
        encodeURIComponent("/" + currentSelectedState.currentSelectedPath);
    }

    if (
      currentVersionId &&
      !currentSelectedState.currentSelectedPath.endsWith("/")
    ) {
      if (currentVersionId !== currentSelectedState.currentSelectedVersionId) {
        setCurrentSelectedState((prevstate) => ({
          currentSelectedPath: prevstate.currentSelectedPath,
          currentSelectedVersionId: currentVersionId as string,
          currentSelectedRepoId: prevstate.currentSelectedRepoId,
          currentSelectedCommentId: prevstate.currentSelectedCommentId
        }));
      }

      const encodedVersionId = encodeURIComponent("?versionId=");

      mergedPath = `${mergedPath}${encodedVersionId}${currentVersionId}`;
    }

    if (
      currentSelectedState.currentSelectedRepoId &&
      (currentSelectedState.currentSelectedPath || currentVersionId)
    ) {
      const encodedRepoId = mergedPath.includes("versionId")
        ? encodeURIComponent("&repoId=")
        : encodeURIComponent("?repoId=");
      mergedPath = `${mergedPath}${encodedRepoId}${currentSelectedState.currentSelectedRepoId}`;
    }

    if (
      !window.location.href.endsWith(mergedPath) &&
      !mergedPath.includes(window.location.pathname + "%3FrepoId") &&
      (currentSelectedState.currentSelectedPath || currentVersionId)
    ) {
      window.history.pushState(
        {},
        currentSelectedState.currentSelectedPath,
        mergedPath
      );
    }

    if (
      currentSelectedState.currentSelectedPath &&
      !currentSelectedState.currentSelectedPath.endsWith("/") &&
      !isCopyMoveModalOpenHook
    ) {
      setTableMenuWasOpenOnPath(currentSelectedState.currentSelectedPath);
    }
  }, [currentSelectedState, activityId]);

  useEffect(() => {
    openProperties && setOpenPropertiesModal(true);
  }, []);

  const {
    createFolderMutation,
    isLoadingCreateFolderMutation,
    errorCreateFolderMutation
  } = useCreateFolderMutation();

  function createFolder(name: string) {
    setIsCreateFolderWindowOpen(false);
    createFolderMutation({
      variables: {
        name,
        parentFolder: currentSelectedState.currentSelectedPath,
        activityId: activityId
      }
    }).then(() => {
      refetchRepoObjects();
    });
  }

  useEffect(() => {
    if (files) {
      let currentFiles;
      if (currentSelectedState.currentSelectedRepoId) {
        currentFiles = files.find(
          (data: GetRepoObjectQueryData) =>
            Number(data.repo.id) ===
            Number(currentSelectedState.currentSelectedRepoId)
        );
      } else {
        currentFiles = files.find(
          (data: GetRepoObjectQueryData) => !data.isReadOnlyRepository
        );
        if (currentFiles) {
          setCurrentSelectedRepoId(currentFiles.repo!.id!);
        }
      }

      if (currentFiles) {
        setCurrentFileList(currentFiles);
      }
      if (
        currentSelectedState.currentSelectedPath &&
        !currentSelectedState.currentSelectedPath.endsWith("/") &&
        !currentSelectedState.currentSelectedVersionId
      ) {
        const foundFile = currentFiles?.repoObjects?.find(
          (element: any) =>
            element.name === currentSelectedState.currentSelectedPath
        );

        if (foundFile) {
          setCurrentSelectedVersionId(foundFile.versionId);
          getFileVersions({
            variables: {
              repoId: currentSelectedState.currentSelectedRepoId,
              versionId: foundFile.versionId
            }
          }).then(({ data }) => {
            setCurrentSelectedRevision(
              data?.getHistoryVersions.find(
                (version: any) => version.versionId === foundFile.versionId
              ).revision
            );
          });
        }
      }

      if (currentSelectedState.currentSelectedVersionId) {
        getFileVersions({
          variables: {
            repoId: currentSelectedState.currentSelectedRepoId,
            versionId: currentSelectedState.currentSelectedVersionId
          }
        }).then(({ data }) => {
          setCurrentSelectedRevision(
            data?.getHistoryVersions.find(
              (version: any) =>
                version.versionId ===
                currentSelectedState.currentSelectedVersionId
            ).revision
          );
        });
      }
    }
  }, [files, currentSelectedState]);

  const onClosePropertiesModal = () => {
    setIsActionOfFilesVisible(false);
    setOpenPropertiesModal(false);
    setTableMenuWasOpenOnPath("");
    setIsPreselectedRevision(false);
  };

  const onCloseTracebilityModal = () => {
    setIsTracebilityReportVisible(false);
    setTableMenuWasOpenOnPath("");
  };

  const history = useHistory();

  useEffect(() => {
    return history.listen((currentLocation) => {
      if (history.action === "POP") {
        if (currentLocation.pathname.includes("/activity/" + activityId)) {
          const page = currentLocation.pathname.split("/").pop();
          const { path, versionId, repoId, commentId } =
            extractIdAndPathFromParameter(page!);

          setCurrentSelectedState({
            currentSelectedPath: path,
            currentSelectedVersionId: versionId ? versionId : "",
            currentSelectedRepoId: repoId ? repoId : null,
            currentSelectedCommentId: commentId ? commentId : null
          });
        } else {
          window.location.replace(currentLocation.pathname);
        }
      }
    });
  }, []);

  return {
    path,
    versionId,
    treeHierarchy,
    isCreateFolderWindowOpen,
    setIsCreateFolderWindowOpen,
    isLoadingCreateFolderMutation,
    errorCreateFolderMutation,
    setCurrentSelectedPath,
    setCurrentSelectedVersionId,
    setCurrentSelectedRepoId,
    setCurrentSelectedRevision,
    error,
    isLoading,
    files,
    createFolder,
    currentSelectedPath: currentSelectedState.currentSelectedPath,
    currentSelectedVersionId: currentSelectedState.currentSelectedVersionId,
    currentSelectedRepoId: currentSelectedState.currentSelectedRepoId,
    currentSelectedRevision,
    refetchRepoObjects: () => {
      refetchRepoObjects().then(() => {
        setIsForceRefreshRunning(false);
      });
      setIsForceRefreshRunning(true);
    },
    isActionOfFilesVisible,
    setIsActionOfFilesVisible,
    isEditActionVisible,
    setIsEditActionVisible,
    tableMenuWasOpenOnPath,
    setTableMenuWasOpenOnPath,
    onClosePropertiesModal,
    onCloseTracebilityModal,
    isTracebilityReportVisible,
    setIsTracebilityReportVisible,
    setForceRefresh,
    forceRefresh,
    dataForceRefresh,
    selectedCommentIdFromMenu,
    setSelectedCommentIdFromMenu,
    isForceRefreshRunning,
    isProcessingExport,
    setIsProcessingExport,
    openPropertiesModal,
    isCopyMoveModalOpenHook,
    setIsCopyMoveModalOpenHook,
    isPreselectedRevision,
    setIsPreselectedRevision,
    currentFileList,
    getRepoObjects,
    repoObjects: repoObjects ? repoObjects?.getRepoObjectsForPath : [],
    repoObjectsError,
    refetchRepoObjectsForPath,
    areRepoObjectsLoading
  };
}
