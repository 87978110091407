import FileIcon from "../../components/abstractComponents/fileIcon";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import MaterialButton from "@mui/material/Button";
import {
  Button,
  Col,
  Form,
  Modal,
  ProgressBar,
  Row,
  Spinner,
  NavDropdown
} from "react-bootstrap";
import {
  getQualityCheckStatusKeyFromValue,
  secondsOfDateFromNow,
  SECONDS_TO_WAIT_FOR_REPO
} from "../../helpers/stringHelper";
import { useUploadFilesModalHook } from "../../hooks/uploadFilesModalHook";
import Error from "../abstractComponents/error";
import { useContext } from "react";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import { ValidityStatus, QualityCheckStatusUI } from "../../backend/types";

export default function UploadFilesModal(props: {
  disabled: boolean;
  currentSelectedPath: string;
  currentSubArray: any[];
  repository: any;
  refetchRepoObjects: Function;
}) {
  const { activityId, activityData } = useContext(PmxActivityDetailsContext);
  const {
    isUploadWindowOpen,
    setIsUploadWindowOpen,
    isLoading,
    filesToBeUploaded,
    filesHashed,
    description,
    setDescription,
    qualityCheckStatus,
    uncompress,
    setUncompress,
    errorFromUploading,
    closeUploadWindow,
    saveFiles,
    uploadFiles,
    uploadLoading,
    createActionError,
    createActionLoading,
    removeCurrentFileFromFiles,
    uploadLinksError,
    getMulipartUploadLinkError,
    completeMultipartUploadError,
    maxNumberOfUploads,
    changeQualityCheckedStatus
  } = useUploadFilesModalHook(
    props.currentSelectedPath,
    props.currentSubArray,
    activityId,
    props.refetchRepoObjects
  );

  return (
    <>
      <NavDropdown.Item
        size="small"
        onClick={() => {
          setIsUploadWindowOpen(true);
        }}
        disabled={props.disabled}
        id="uploadItem"
        data-testid="uploadItem"
      >
        <CloudUploadIcon /> Upload
      </NavDropdown.Item>

      <Modal
        show={isUploadWindowOpen}
        dialogClassName="modal-75w"
        onHide={closeUploadWindow}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Upload File into &quot;{activityData.trialNumber}/
            {props.currentSelectedPath}&quot;
            {isLoading && (
              <Spinner animation="border" className="spinner_color" />
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(uploadLoading || createActionLoading || filesToBeUploaded > 0) && (
            <div className="center_div">
              <Spinner animation="border" className="spinner_color" />
              <br />
              Uploading...
              <br />
            </div>
          )}
          {maxNumberOfUploads > 1 && (
            <>
              <ProgressBar
                animated
                now={
                  //We add one so it does not show an empty rectangle
                  ((maxNumberOfUploads -
                    (filesToBeUploaded > 0 ? filesToBeUploaded : 0)) /
                    maxNumberOfUploads) *
                  100
                }
                label={`${Math.round(
                  ((maxNumberOfUploads -
                    (filesToBeUploaded > 0 ? filesToBeUploaded : 0)) /
                    maxNumberOfUploads) *
                    100
                )}%`}
              />
              {filesToBeUploaded && maxNumberOfUploads && (
                <p className="uploading_info_details">
                  In queue: {filesToBeUploaded > 0 ? filesToBeUploaded : 0}/
                  {maxNumberOfUploads} file parts left
                </p>
              )}
              <br />
              <br />
            </>
          )}

          <form encType="multipart/form-data" method="POST" id="uploadFileForm">
            <Form.Group className="mb-3">
              <Form.Label>
                File <span className="red_form">*</span>
              </Form.Label>
              <Form.Control
                disabled={isLoading}
                type="file"
                required
                name="file"
                onChange={saveFiles}
                id="uploadFileInput"
                data-testid="uploadFileInput"
                multiple
              />
            </Form.Group>
            {filesHashed.map((file) => (
              <div
                data-testid="fileOutputAdfFormElement"
                key={file.file}
                className={
                  file.willOverwrite
                    ? "drop_comment_file drop_comment_file_warning"
                    : "drop_comment_file"
                }
              >
                <FileIcon
                  fileName={file.file}
                  overallValidityStatus={ValidityStatus.NotSet}
                  fontSize={"small"}
                />{" "}
                {file.file +
                  (file.willOverwrite ? " (will overwrite file)" : "")}
                <MaterialButton
                  variant="text"
                  onClick={() => removeCurrentFileFromFiles(file.file)}
                  id="deleteRepliedToCommentButton"
                  className="float-end small_mui_button"
                  size="small"
                >
                  X
                </MaterialButton>
              </div>
            ))}

            {filesHashed.filter((file: any) => {
              return file.file.endsWith(".zip");
            }).length > 0 && (
              <>
                <br />
                <Row>
                  <Col>
                    <Form.Label>
                      Uncompress on Upload?{" "}
                      {secondsOfDateFromNow(props.repository.created) <
                        SECONDS_TO_WAIT_FOR_REPO && (
                        <span className="red_form">
                          (you have to wait for{" "}
                          {SECONDS_TO_WAIT_FOR_REPO -
                            secondsOfDateFromNow(props.repository.created)}{" "}
                          more seconds until the repositoy is ready)
                        </span>
                      )}
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      disabled={
                        secondsOfDateFromNow(props.repository.created) <
                        SECONDS_TO_WAIT_FOR_REPO
                      }
                      checked={uncompress}
                      id="uncompressUploadValidCheckbox"
                      onChange={() => setUncompress(!uncompress)}
                    />
                  </Col>
                </Row>
              </>
            )}
            <br />
            <Form.Label>Upload Description</Form.Label>
            <Form.Control
              type="text"
              required
              data-testid="uploadDescriptionFormElement"
              placeholder="Enter description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              id="uploadDescriptionFormElement"
            />
            <br />
            <Row>
              <Col xs={6}>
                <Form.Label>Quality Control Status</Form.Label>
              </Col>
              <Col xs={6}>
                <Form.Select
                  value={String(
                    getQualityCheckStatusKeyFromValue(qualityCheckStatus)
                  )}
                  onChange={changeQualityCheckedStatus}
                  key="upload_quality_controlled"
                  id="uploadQualityControlledFormElement"
                >
                  {Object.entries(QualityCheckStatusUI).map((element) => (
                    <option key={element[0]} value={element[0]}>
                      {element[1]}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            disabled={isLoading} //disabled={filesToBeUploaded > 0} //we might put this back later
            onClick={closeUploadWindow}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={
              isLoading || filesHashed.length === 0 || filesToBeUploaded > 0
            }
            id="finishUploadButton"
            data-testid="finishUploadButton"
            onClick={uploadFiles}
          >
            Upload
          </Button>
        </Modal.Footer>
      </Modal>

      {(uploadLinksError ||
        errorFromUploading ||
        createActionError ||
        getMulipartUploadLinkError ||
        completeMultipartUploadError) && (
        <Error
          error={
            uploadLinksError ||
            errorFromUploading ||
            createActionError ||
            getMulipartUploadLinkError ||
            completeMultipartUploadError
          }
        />
      )}
    </>
  );
}
