// TODO: have a talk with frontend team which tool to use to generate queries
// I just used https://transform.tools/graphql-to-typescript to generate
// the types below from the schema definition of the backend
// but there are also more sophisticated solutions
export declare type ValidityStatusType = keyof typeof ValidityStatus;

export enum QualityCheckStatusUI {
  NOT_STARTED = "Not Started",
  SUCCESSFUL = "Successful",
  MARKED = "Marked",
  FAILED = "Failed"
}
export declare type QualityCheckStatusType = keyof typeof QualityCheckStatusUI;

export enum ActionSourceUI {
  EXTERNAL_SYSTEM = "External System",
  PHIL_BASE_SYSTEM = "PHIL Base System",
  PHIL_INTERACTIVE_ENVIRONMENT = "PHIL Interactive Environment"
}
export declare type ActionSourceType = keyof typeof ActionSourceUI;

export enum ActionTypeUI {
  DOWNLOAD = "Download",
  SWAN_IMPORT = "SWANImport",
  UPLOAD = "Upload",
  ADF_CREATION = "ADF Creation",
  ARCHIVE = "Compress",
  UNCOMPRESS = "Uncompress",
  MODSPACE_EXPORT = "ModSpaceExport",
  MODSPACE_IMPORT = "ModSpaceImport",
  HIDE = "Hide",
  RENAME = "Rename",
  COPY = "Copy",
  MOVE = "Move",
  PLOT_CREATION = "Plot Creation",
  REPORT_CREATION = "Report Creation",
  MODEL_EXECUTION = "Model Execution",
  OTHER_ACTION = "Other Action",
  SPECTRUM_IMPORT = "SpectrumImport",
  SPM_SVN_EXPORT = "SpmSvnExport",
  SPM_SVN_IMPORT = "SpmSvnImport",
  RESTORE = "Restore"
}
export declare type ActionTypeType = keyof typeof ActionTypeUI;

//*******REPLACE FROM HERE DOWN********/
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
};

export type Query = {
  __typename?: "Query";
  getUsers: Array<Maybe<User>>;
  getRepoObjects?: Maybe<GetRepoObjectQueryPageData>;
  getHistoricRepoObjects?: Maybe<GetHistoricRepoObjectQueryData>;
  getPathVersions: Array<RepoObject>;
  getHistoryVersions: Array<RepoObject>;
  getRepos: Array<Maybe<Repo>>;
  getRepoDetails: Repo;
  getAuditTrailEvents?: Maybe<GetAuditTrailOutput>;
  getFilePartialContent: FilePartialContent;
  getDownloadLinks: GetDownloadLinksOutput;
  getActivityTemplates: Array<ActivityTemplate>;
  getMyPmxActivities: Array<Maybe<PmxActivity>>;
  getPmxActivityDetails: PmxActivity;
  getSwanContent: Array<Maybe<SwanFile>>;
  getAccessRights?: Maybe<AccessRight>;
  getCommentDetails: Comment;
  getSpectrumStudyList: Array<Maybe<SpectrumStudy>>;
  getDatasetForStudy: Array<Maybe<SpectrumDataset>>;
  getSpmReFolders: GetSpmReFoldersOutput;
  getSpmReRevisions: GetSpmReRevisionsOutput;
  getSpmRePaths: GetSpmRePathsOutput;
  getModspaceFolders: GetModspaceFoldersOutput;
  getModspaceRevisions: GetModspaceRevisionsOutput;
  getModspacePaths: GetModspacePathsOutput;
  getTableQueryData: SpectrumTableQueryData;
  getExternalDownloadLink: Array<DownloadLink>;
  getExternalUploadLinks: CreateUploadLinksOutput;
  getSpectrumTableDetails: SpectrumTableDetails;
  getRepoObjectsForPath: Array<Maybe<RepoObject>>;
  getRepoObjectForMd5?: Maybe<RepoObject>;
  getRepoObjectsDifference: RepoObjectsDifference;
  getTraceabilityActions: GetActionsOutput;
  getActions: GetActionsOutput;
  getCreationalActions: GetActionsOutput;
  getConsumingActions: GetActionsOutput;
  getAction: Action;
  getHistoryLabels: GetHistoryLabelsOutput;
  getAuditTrailById?: Maybe<AuditTrailEvent>;
  getUserValidations: GetUserValidationsOutput;
};

export type QueryGetUsersArgs = {
  input?: Maybe<GetUsersInput>;
};

export type QueryGetRepoObjectsArgs = {
  input: GetRepoObjectsInput;
};

export type QueryGetHistoricRepoObjectsArgs = {
  input: GetHistoricRepoObjectsInput;
};

export type QueryGetPathVersionsArgs = {
  input?: Maybe<GetPathVersionsInput>;
};

export type QueryGetHistoryVersionsArgs = {
  input?: Maybe<GetHistoryVersionsInput>;
};

export type QueryGetRepoDetailsArgs = {
  input: GetRepoDetailsInput;
};

export type QueryGetAuditTrailEventsArgs = {
  input: GetAuditTrailEventsInput;
};

export type QueryGetFilePartialContentArgs = {
  input?: Maybe<GetFilePartialContentInput>;
};

export type QueryGetDownloadLinksArgs = {
  input?: Maybe<GetDownloadLinksInput>;
};

export type QueryGetPmxActivityDetailsArgs = {
  input: GetPmxActivityDetailsInput;
};

export type QueryGetSwanContentArgs = {
  input?: Maybe<GetSwanContentInput>;
};

export type QueryGetAccessRightsArgs = {
  input?: Maybe<GetAccessRightsInput>;
};

export type QueryGetCommentDetailsArgs = {
  input: GetCommentDetailsInput;
};

export type QueryGetSpectrumStudyListArgs = {
  input?: Maybe<GetSpectrumStudyListInput>;
};

export type QueryGetDatasetForStudyArgs = {
  input?: Maybe<GetDatasetForStudyInput>;
};

export type QueryGetSpmReFoldersArgs = {
  input: GetSpmReFoldersInput;
};

export type QueryGetSpmReRevisionsArgs = {
  input: GetSpmReRevisionsInput;
};

export type QueryGetSpmRePathsArgs = {
  input: GetSpmRePathsInput;
};

export type QueryGetModspaceFoldersArgs = {
  input: GetModspaceFoldersInput;
};

export type QueryGetModspaceRevisionsArgs = {
  input: GetModspaceRevisionsInput;
};

export type QueryGetModspacePathsArgs = {
  input: GetModspacePathsInput;
};

export type QueryGetTableQueryDataArgs = {
  input?: Maybe<GetTableQueryDataInput>;
};

export type QueryGetExternalDownloadLinkArgs = {
  input?: Maybe<GetExternalDownloadLinkInput>;
};

export type QueryGetExternalUploadLinksArgs = {
  input?: Maybe<GetExternalUploadLinkInput>;
};

export type QueryGetSpectrumTableDetailsArgs = {
  input?: Maybe<GetSpectrumTableDetailsInput>;
};

export type QueryGetRepoObjectsForPathArgs = {
  input?: Maybe<GetRepoObjectsForPathInput>;
};

export type QueryGetRepoObjectForMd5Args = {
  input: GetRepoObjectforMd5Input;
};

export type QueryGetRepoObjectsDifferenceArgs = {
  input: GetRepoObjectsDifference;
};

export type QueryGetTraceabilityActionsArgs = {
  input: GetTraceabilityActionsInput;
};

export type QueryGetActionsArgs = {
  input?: Maybe<GetActionsInput>;
};

export type QueryGetCreationalActionsArgs = {
  input: GetCreationalActionsInput;
};

export type QueryGetConsumingActionsArgs = {
  input: GetConsumingActionsInput;
};

export type QueryGetActionArgs = {
  input: GetActionInput;
};

export type QueryGetHistoryLabelsArgs = {
  input: GetHistoryLabelsInput;
};

export type QueryGetAuditTrailByIdArgs = {
  input?: Maybe<GetAuditTrailByIdInput>;
};

export type QueryGetUserValidationsArgs = {
  input: GetUserValidationsInput;
};

export enum AuditTrailEventActionType {
  AddExternalUser = "AddExternalUser",
  CreateRepository = "CreateRepository",
  CreateUser = "CreateUser",
  DeleteRepository = "DeleteRepository",
  EditRepository = "EditRepository",
  CreateFile = "CreateFile",
  ActivateUser = "ActivateUser",
  CreatePmxActivity = "CreatePmxActivity",
  EditPmxActivity = "EditPmxActivity",
  DeleteAccessRights = "DeleteAccessRights",
  EditAccessRights = "EditAccessRights",
  CreateAction = "CreateAction",
  RenameObject = "RenameObject",
  CopyObject = "CopyObject",
  MoveObject = "MoveObject",
  HideObject = "HideObject",
  EditAction = "EditAction",
  Restore = "Restore"
}

export enum ActionType {
  Download = "DOWNLOAD",
  SwanImport = "SWAN_IMPORT",
  Upload = "UPLOAD",
  AdfCreation = "ADF_CREATION",
  Archive = "ARCHIVE",
  Uncompress = "UNCOMPRESS",
  ModspaceExport = "MODSPACE_EXPORT",
  ModspaceImport = "MODSPACE_IMPORT",
  Rename = "RENAME",
  Copy = "COPY",
  Move = "MOVE",
  Hide = "HIDE",
  PlotCreation = "PLOT_CREATION",
  ReportCreation = "REPORT_CREATION",
  ModelExecution = "MODEL_EXECUTION",
  OtherAction = "OTHER_ACTION",
  SpectrumImport = "SPECTRUM_IMPORT",
  SpmSvnExport = "SPM_SVN_EXPORT",
  SpmSvnImport = "SPM_SVN_IMPORT",
  Restore = "RESTORE"
}

export enum ActionGroupType {
  Internal = "INTERNAL",
  Processing = "PROCESSING",
  Interface = "INTERFACE"
}

export enum ActionStatus {
  Success = "SUCCESS",
  Pending = "PENDING",
  Error = "ERROR"
}

export enum QualityCheckStatus {
  Successful = "SUCCESSFUL",
  Marked = "MARKED",
  Failed = "FAILED",
  NotStarted = "NOT_STARTED"
}

export enum ActionSource {
  ExternalSystem = "EXTERNAL_SYSTEM",
  PhilBaseSystem = "PHIL_BASE_SYSTEM",
  PhilInteractiveEnvironment = "PHIL_INTERACTIVE_ENVIRONMENT",
  PhilExecutionEnvironment = "PHIL_EXECUTION_ENVIRONMENT"
}

export enum ActionFileType {
  Input = "INPUT",
  Output = "OUTPUT",
  Describing = "DESCRIBING",
  Script = "SCRIPT"
}

export enum ValidityStatus {
  Valid = "VALID",
  Invalid = "INVALID",
  NotSet = "NOT_SET"
}

export type User = {
  __typename?: "User";
  email: Scalars["String"];
  userGroup?: Maybe<Scalars["String"]>;
  roleArn?: Maybe<Scalars["String"]>;
  isPmx?: Maybe<Scalars["Boolean"]>;
  company?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type RepoObject = {
  __typename?: "RepoObject";
  name: Scalars["String"];
  isDir: Scalars["Boolean"];
  revision?: Maybe<Scalars["Int"]>;
  versionId?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["String"]>;
  joinCommentType?: Maybe<Scalars["Int"]>;
  md5?: Maybe<Scalars["String"]>;
  size?: Maybe<Scalars["Float"]>;
  comments?: Maybe<Array<Maybe<Comment>>>;
  repo?: Maybe<Repo>;
  validity?: Maybe<RepoObjectValidity>;
};

export type RepoObjectValidity = {
  __typename?: "RepoObjectValidity";
  qualityCheckStatus: QualityCheckStatus;
  overallValidityStatus: ValidityStatus;
  userValidityStatus?: Maybe<ValidityStatus>;
  outdatedUserValidityStatus?: Maybe<Scalars["Boolean"]>;
  systemValidityStatus?: Maybe<ValidityStatus>;
};

export type GetUserValidationsOutput = {
  __typename?: "GetUserValidationsOutput";
  totalCount: Scalars["Int"];
  userValidations: Array<UserValidation>;
};

export type UserValidation = {
  __typename?: "UserValidation";
  id: Scalars["ID"];
  revision: Scalars["Int"];
  versionId: Scalars["ID"];
  validityStatus: ValidityStatus;
  created: Scalars["String"];
  createdBy: Scalars["String"];
  description: Scalars["String"];
  outdatedByActionHistory?: Maybe<ActionHistory>;
  outdatedByUserValidation?: Maybe<UserValidation>;
};

export type GetRepoObjectQueryPageData = {
  __typename?: "GetRepoObjectQueryPageData";
  data?: Maybe<GetRepoObjectQueryData>;
  nextPageNumber?: Maybe<Scalars["Int"]>;
  nextRepoId?: Maybe<Scalars["Int"]>;
};

export type GetRepoObjectQueryData = {
  __typename?: "GetRepoObjectQueryData";
  repoObjects: Array<RepoObject>;
  isReadOnlyRepository: Scalars["Boolean"];
  repo: Repo;
};

export type GetHistoricRepoObjectQueryData = {
  __typename?: "GetHistoricRepoObjectQueryData";
  repoObjects: Array<RepoObject>;
  isReadOnlyRepository?: Maybe<Scalars["Boolean"]>;
  repo?: Maybe<Repo>;
  historicDatetime?: Maybe<Scalars["String"]>;
  action?: Maybe<Action>;
  historyLabel?: Maybe<Scalars["String"]>;
  page?: Maybe<Scalars["Int"]>;
  totalPages?: Maybe<Scalars["Int"]>;
};

export type RepoObjectsDifference = {
  __typename?: "RepoObjectsDifference";
  differences: Scalars["String"];
};

export type Repo = {
  __typename?: "Repo";
  id: Scalars["ID"];
  name: Scalars["String"];
  isStandard: Scalars["Boolean"];
  accessRight?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["String"]>;
};

export type AuditTrailEvent = {
  __typename?: "AuditTrailEvent";
  id: Scalars["ID"];
  created: Scalars["String"];
  userId: Scalars["String"];
  repository?: Maybe<Repo>;
  actionType: AuditTrailEventActionType;
  data?: Maybe<Scalars["JSON"]>;
};

export type FilePartialContent = {
  __typename?: "FilePartialContent";
  content: Scalars["String"];
  isTruncated: Scalars["Boolean"];
  isPreviewAvailable: Scalars["Boolean"];
};

export type GetDownloadLinksOutput = {
  __typename?: "GetDownloadLinksOutput";
  actionId: Scalars["ID"];
  actionStatus: ActionStatus;
  presignedUrls?: Maybe<Array<DownloadLink>>;
};

export type GetActionsOutput = {
  __typename?: "GetActionsOutput";
  actions: Array<Action>;
  totalCount: Scalars["Int"];
};

export type GetAuditTrailOutput = {
  __typename?: "GetAuditTrailOutput";
  auditTrail: Array<Maybe<AuditTrailEvent>>;
  totalRowCount?: Maybe<Scalars["Int"]>;
};

export type DownloadLink = {
  __typename?: "DownloadLink";
  url: Scalars["String"];
  absolutePath: Scalars["String"];
};

export type Action = {
  __typename?: "Action";
  id: Scalars["ID"];
  actionType: ActionType;
  actionGroupType: ActionGroupType;
  actionFiles: Array<ActionFile>;
  hasAdditionalFilesLinked: Scalars["Boolean"];
  startDatetime: Scalars["String"];
  user: User;
  description?: Maybe<Scalars["String"]>;
  pmxActivity: PmxActivity;
  sourceFolder?: Maybe<Scalars["String"]>;
  sourceFiles?: Maybe<Array<Scalars["String"]>>;
  actionStatus: ActionStatus;
  actionSource: ActionSource;
  actionQualityCheckStatus: QualityCheckStatus;
  actionStatusDescription?: Maybe<Scalars["String"]>;
  additionalDetails?: Maybe<Scalars["String"]>;
  jobId?: Maybe<Scalars["ID"]>;
  historyLabel?: Maybe<HistoryLabel>;
  actionHistory: Array<ActionHistory>;
};

export type HistoryLabel = {
  __typename?: "HistoryLabel";
  id: Scalars["ID"];
  name: Scalars["String"];
  actionId: Scalars["ID"];
  updatedBy: Scalars["String"];
  updated: Scalars["String"];
};

export type GetHistoryLabelsOutput = {
  __typename?: "GetHistoryLabelsOutput";
  historyLabels: Array<HistoryLabel>;
  totalCount: Scalars["Int"];
};

export type ActionFile = {
  __typename?: "ActionFile";
  actionFileType: ActionFileType;
  file?: Maybe<RepoObject>;
};

export type ActionHistory = {
  __typename?: "ActionHistory";
  id: Scalars["ID"];
  revision: Scalars["Int"];
  userId: Scalars["String"];
  updated: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  actionQualityCheckStatus: QualityCheckStatus;
  actionType: ActionType;
  actionId: Scalars["ID"];
};

export type ActivityTemplate = {
  __typename?: "ActivityTemplate";
  name: Scalars["String"];
  description: Scalars["String"];
  directories: Array<Scalars["String"]>;
};

export type PmxActivity = {
  __typename?: "PmxActivity";
  id: Scalars["ID"];
  trialNumber: Scalars["String"];
  mainRepository: Repo;
  bayNumber?: Maybe<Scalars["String"]>;
  projectId?: Maybe<Scalars["String"]>;
  trialShortDescription?: Maybe<Scalars["String"]>;
  conversations: Array<Maybe<Conversation>>;
  allAccessUser: Array<Maybe<User>>;
  readOnlyRepositories?: Maybe<Array<Maybe<Repo>>>;
  source?: Maybe<Scalars["JSON"]>;
};

export type Conversation = {
  __typename?: "Conversation";
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  comments: Array<Maybe<Comment>>;
};

export type Comment = {
  __typename?: "Comment";
  id: Scalars["ID"];
  text: Scalars["String"];
  created?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<Scalars["String"]>;
  replyComment?: Maybe<Comment>;
  pmxActivity?: Maybe<PmxActivity>;
  referencedFiles?: Maybe<Array<Maybe<RepoObject>>>;
  referencedActions?: Maybe<Array<Maybe<Action>>>;
};

export type SwanFile = {
  __typename?: "SwanFile";
  name: Scalars["String"];
  size?: Maybe<Scalars["Float"]>;
};

export type SpectrumLoginData = {
  __typename?: "SpectrumLoginData";
  tokenType?: Maybe<Scalars["String"]>;
  expiresIn?: Maybe<Scalars["Int"]>;
  accessToken: Scalars["String"];
};

export type SpmReMeta = {
  __typename?: "SpmReMeta";
  spmReFolder: Scalars["String"];
  spmReRevisions: Array<Scalars["String"]>;
};

export type SpmRePath = {
  __typename?: "SpmRePath";
  name: Scalars["String"];
  size?: Maybe<Scalars["Float"]>;
};

export type ModspaceMeta = {
  __typename?: "ModspaceMeta";
  modspaceFolder: Scalars["String"];
  modspaceRevisions: Array<Scalars["String"]>;
};

export type ModspaceContent = {
  __typename?: "ModspaceContent";
  name: Scalars["String"];
  size?: Maybe<Scalars["Float"]>;
};

export type AccessRight = {
  __typename?: "AccessRight";
  name?: Maybe<Scalars["String"]>;
  reader?: Maybe<Array<Maybe<Scalars["String"]>>>;
  contributor?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type GetRepoObjectsInput = {
  activityId: Scalars["ID"];
  pageNumber?: Maybe<Scalars["Int"]>;
  repoId?: Maybe<Scalars["Int"]>;
};

export type GetRepoObjectforMd5Input = {
  pmxActivityId: Scalars["Int"];
  md5: Scalars["String"];
};

export type GetHistoricRepoObjectsInput = {
  repoId: Scalars["ID"];
  historicDatetime?: Maybe<Scalars["String"]>;
  actionId?: Maybe<Scalars["ID"]>;
  page?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
  path?: Maybe<Scalars["String"]>;
  isTreeView?: Maybe<Scalars["Boolean"]>;
};

export type GetHistoryLabelsInput = {
  activityId: Scalars["ID"];
  page?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
};

export type GetPathVersionsInput = {
  repoId?: Maybe<Scalars["ID"]>;
  activityId?: Maybe<Scalars["ID"]>;
  absolutePath?: Maybe<Scalars["String"]>;
  forceRefresh?: Maybe<Scalars["Boolean"]>;
};

export type GetHistoryVersionsInput = {
  repoId?: Maybe<Scalars["ID"]>;
  versionId: Scalars["ID"];
  forceRefresh?: Maybe<Scalars["Boolean"]>;
};

export type GetRepoDetailsInput = {
  repoId: Scalars["ID"];
};

export type GetActionInput = {
  actionId: Scalars["ID"];
};

export type GetLastActionInput = {
  dateTime: Scalars["String"];
  pmxActivityId: Scalars["ID"];
};

export type GetCommentDetailsInput = {
  commentId: Scalars["ID"];
};

export type GetFilePartialContentInput = {
  absolutePath: Scalars["String"];
  repoId: Scalars["ID"];
  versionId?: Maybe<Scalars["ID"]>;
  lineLimit?: Maybe<Scalars["Int"]>;
};

export type GetVersionPartialContentInput = {
  versionId: Scalars["ID"];
  repoId: Scalars["ID"];
  lineLimit?: Maybe<Scalars["Int"]>;
};

export type GetDownloadLinksInput = {
  activityId: Scalars["ID"];
  absolutePaths: Array<Maybe<Scalars["String"]>>;
  versionIds: Array<Maybe<Scalars["ID"]>>;
  repoId?: Maybe<Scalars["ID"]>;
};

export type GetPmxActivityDetailsInput = {
  id: Scalars["ID"];
};

export type GetAuditTrailEventsInput = {
  page?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<Maybe<Sort>>>;
  filter?: Maybe<Filter>;
};

export type GetActionsInput = {
  activityId?: Maybe<Scalars["ID"]>;
  createdBefore?: Maybe<Scalars["String"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
};

export type GetTraceabilityActionsInput = {
  versionId: Scalars["ID"];
  pageNumber?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
};

export type GetCreationalActionsInput = {
  versionIds: Array<Scalars["ID"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
};

export type GetConsumingActionsInput = {
  versionIds: Array<Scalars["ID"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
};

export type GetUsersInput = {
  forceRefresh?: Maybe<Scalars["Boolean"]>;
};

export type GetSwanContentInput = {
  swanUser: Scalars["String"];
  encryptedPassword: Scalars["String"];
  absolutePath: Scalars["String"];
};

export type GetSpmReFoldersInput = {
  username: Scalars["String"];
  encryptedPassword: Scalars["String"];
  search: Scalars["String"];
};

export type GetSpmReFoldersOutput = {
  __typename?: "GetSpmReFoldersOutput";
  spmReFolders: Array<Scalars["String"]>;
};

export type GetSpmReRevisionsInput = {
  username: Scalars["String"];
  encryptedPassword: Scalars["String"];
  spmReFolder: Scalars["String"];
};

export type GetSpmReRevisionsOutput = {
  __typename?: "GetSpmReRevisionsOutput";
  spmReRevisions: Array<Scalars["String"]>;
};

export type GetSpmRePathsInput = {
  username: Scalars["String"];
  encryptedPassword: Scalars["String"];
  spmReFolder: Scalars["String"];
  spmReRevision?: Maybe<Scalars["String"]>;
};

export type GetSpmRePathsOutput = {
  __typename?: "GetSpmRePathsOutput";
  spmRePaths: Array<SpmRePath>;
};

export type GetModspaceFoldersInput = {
  username: Scalars["String"];
  encryptedPassword: Scalars["String"];
  search: Scalars["String"];
};

export type GetModspaceFoldersOutput = {
  __typename?: "GetModspaceFoldersOutput";
  modspaceFolders: Array<Scalars["String"]>;
};

export type GetModspaceRevisionsInput = {
  username: Scalars["String"];
  encryptedPassword: Scalars["String"];
  modspaceFolder: Scalars["String"];
};

export type GetModspaceRevisionsOutput = {
  __typename?: "GetModspaceRevisionsOutput";
  modspaceRevisions: Array<Scalars["String"]>;
};

export type GetModspacePathsInput = {
  username: Scalars["String"];
  encryptedPassword: Scalars["String"];
  modspaceFolder: Scalars["String"];
  modspaceRevision?: Maybe<Scalars["String"]>;
};

export type GetModspacePathsOutput = {
  __typename?: "GetModspacePathsOutput";
  modspacePaths: Array<SpmRePath>;
};

export type GetRepoObjectsForPathInput = {
  path: Scalars["String"];
  repoId: Scalars["Int"];
  pmxActivityId: Scalars["Int"];
};

export type GetAccessRightsInput = {
  repoId?: Maybe<Scalars["ID"]>;
};

export type RepoObjectDifferenceInput = {
  repoId: Scalars["ID"];
  versionId: Scalars["ID"];
};

export type GetRepoObjectsDifference = {
  source: RepoObjectDifferenceInput;
  target: RepoObjectDifferenceInput;
};

export type Mutation = {
  __typename?: "Mutation";
  deleteTestRepos: Scalars["Boolean"];
  createUploadLinks: CreateUploadLinksOutput;
  createMultipartUpload: CreateMultipartUploadOutput;
  getMultipartUploadLinks: GetMultipartUploadLinksOutput;
  completeMultipartUpload: CompleteMultipartUploadOutput;
  renameObjects: Array<Maybe<Scalars["String"]>>;
  copyObjects: Array<Maybe<Scalars["String"]>>;
  moveObjects: Array<Maybe<Scalars["String"]>>;
  hideObjects: Array<Maybe<Scalars["String"]>>;
  addExternalUser: User;
  createPmxActivity: PmxActivity;
  editPmxActivity: Scalars["Boolean"];
  deletePmxActivity: Scalars["Boolean"];
  createFolder: RepoObject;
  createArchive: CreateArchiveOutput;
  activateUser: Scalars["Boolean"];
  createConversation: Conversation;
  createComment: Comment;
  editComment: Comment;
  deleteComment: Scalars["Boolean"];
  importSwanContent: ImportSwanContentOutput;
  editAccessRights?: Maybe<AccessRight>;
  deleteAccessRights?: Maybe<AccessRight>;
  exportToSpmRe: ExportToSpmReOutput;
  exportToModspace: ExportToModspaceOutput;
  importSpmReContent: ImportSpmReContentOutput;
  importModspaceContent: ImportModspaceContentOutput;
  importSpectrumData: Scalars["Boolean"];
  importSpectrumSchema: Scalars["Boolean"];
  createExternalDownloadLink: Scalars["String"];
  createExternalUploadLink: Scalars["String"];
  downloadAuditTrailEvents: AuditLogDownloadLinkOutput;
  uncompressArchive: Scalars["Boolean"];
  forceRefreshPmxActivity: Scalars["Boolean"];
  restoreFileVersion: RestoreFileVersionOutput;
  restoreFilePath: RestoreFileVersionOutput;
  createAction: Action;
  editAction: Action;
  updateActionStatus: Action;
  setHistoryLabel: HistoryLabel;
  deleteHistoryLabel: Scalars["Boolean"];
  createUserValidation: UserValidation;
};

export type MutationCreateUploadLinksArgs = {
  input: CreateUploadLinksInput;
};

export type MutationCreateMultipartUploadArgs = {
  input: CreateMultipartUploadInput;
};

export type MutationGetMultipartUploadLinksArgs = {
  input: GetMultipartUploadLinksInput;
};

export type MutationCompleteMultipartUploadArgs = {
  input: CompleteMultipartUploadInput;
};

export type MutationRenameObjectsArgs = {
  input: RenameObjectsInput;
};

export type MutationCopyObjectsArgs = {
  input: CopyObjectsInput;
};

export type MutationMoveObjectsArgs = {
  input: MoveObjectsInput;
};

export type MutationHideObjectsArgs = {
  input: HideObjectsInput;
};

export type MutationAddExternalUserArgs = {
  input: AddExternalUserInput;
};

export type MutationCreatePmxActivityArgs = {
  input: CreatePmxActivityInput;
};

export type MutationEditPmxActivityArgs = {
  input: EditPmxActivityInput;
};

export type MutationDeletePmxActivityArgs = {
  input: DeletePmxActivityInput;
};

export type MutationCreateFolderArgs = {
  input: CreateFolderInput;
};

export type MutationCreateArchiveArgs = {
  input: CreateArchiveInput;
};

export type MutationCreateConversationArgs = {
  input: CreateConversationInput;
};

export type MutationCreateCommentArgs = {
  input: CreateCommentInput;
};

export type MutationEditCommentArgs = {
  input: EditCommentInput;
};

export type MutationDeleteCommentArgs = {
  input: DeleteCommentInput;
};

export type MutationImportSwanContentArgs = {
  input: ImportSwanContentInput;
};

export type MutationEditAccessRightsArgs = {
  input?: Maybe<EditAccessRightsInput>;
};

export type MutationDeleteAccessRightsArgs = {
  input?: Maybe<DeleteAccessRightsInput>;
};

export type MutationExportToSpmReArgs = {
  input: ExportToSpmReInput;
};

export type MutationExportToModspaceArgs = {
  input: ExportToModspaceInput;
};

export type MutationImportSpmReContentArgs = {
  input: ImportSpmReContentInput;
};

export type MutationImportModspaceContentArgs = {
  input: ImportModspaceContentInput;
};

export type MutationImportSpectrumDataArgs = {
  input: ImportSpectrumDataInput;
};

export type MutationImportSpectrumSchemaArgs = {
  input: ImportSpectrumSchemaInput;
};

export type MutationCreateExternalDownloadLinkArgs = {
  input: CreateExternalDownloadLinkInput;
};

export type MutationCreateExternalUploadLinkArgs = {
  input: CreateExternalUploadLinkInput;
};

export type MutationUncompressArchiveArgs = {
  input: UncompressArchiveInput;
};

export type MutationForceRefreshPmxActivityArgs = {
  input: ForceRefreshPmxActivityInput;
};

export type MutationRestoreFileVersionArgs = {
  input: RestoreFileVersionInput;
};

export type MutationRestoreFilePathArgs = {
  input: RestoreFilePathInput;
};

export type MutationCreateActionArgs = {
  input: CreateActionInput;
};

export type MutationEditActionArgs = {
  input: EditActionInput;
};

export type MutationUpdateActionStatusArgs = {
  input: UpdateActionStatusInput;
};

export type MutationSetHistoryLabelArgs = {
  input: SetHistoryLabelInput;
};

export type MutationCreateUserValidationArgs = {
  input: CreateUserValidationInput;
};

export type CreateUploadLinksOutput = {
  __typename?: "CreateUploadLinksOutput";
  actionId: Scalars["Int"];
  actionStatus: ActionStatus;
  presignedUrls?: Maybe<Array<UploadLink>>;
};

export type CreateMultipartUploadOutput = {
  __typename?: "CreateMultipartUploadOutput";
  actionId: Scalars["Int"];
  actionStatus: ActionStatus;
  uploadId?: Maybe<Scalars["String"]>;
};

export type MultipartUploadLink = {
  __typename?: "MultipartUploadLink";
  partNumber: Scalars["Int"];
  url: Scalars["String"];
};

export type GetMultipartUploadLinksOutput = {
  __typename?: "GetMultipartUploadLinksOutput";
  presignedUrls?: Maybe<Array<MultipartUploadLink>>;
};

export type CompleteMultipartUploadOutput = {
  __typename?: "CompleteMultipartUploadOutput";
  bucket?: Maybe<Scalars["String"]>;
  absolutePath?: Maybe<Scalars["String"]>;
  versionId?: Maybe<Scalars["String"]>;
  actionId: Scalars["Int"];
  actionStatus: ActionStatus;
};

export type UploadLink = {
  __typename?: "UploadLink";
  url: Scalars["String"];
  fields: Scalars["String"];
};

export type CreateArchiveOutput = {
  __typename?: "CreateArchiveOutput";
  actionId: Scalars["ID"];
  actionStatus: ActionStatus;
  archive?: Maybe<RepoObject>;
};

export type ImportSwanContentOutput = {
  __typename?: "ImportSwanContentOutput";
  actionId: Scalars["ID"];
  actionStatus: ActionStatus;
  imported?: Maybe<Array<RepoObject>>;
};

export type ExportToSpmReOutput = {
  __typename?: "ExportToSpmReOutput";
  actionId: Scalars["Int"];
  actionStatus: ActionStatus;
};

export type ExportToModspaceOutput = {
  __typename?: "ExportToModspaceOutput";
  actionId: Scalars["Int"];
  actionStatus: ActionStatus;
};

export type MapPathMd5Sum = {
  __typename?: "MapPathMd5Sum";
  path: Scalars["String"];
  md5Sum: Scalars["String"];
};

export type SpectrumStudy = {
  __typename?: "SpectrumStudy";
  study: Scalars["ID"];
  system?: Maybe<Scalars["String"]>;
  therapeuticArea?: Maybe<Scalars["String"]>;
  indication?: Maybe<Scalars["String"]>;
  drugProgramCode?: Maybe<Scalars["String"]>;
};

export type SpectrumDataset = {
  __typename?: "SpectrumDataset";
  systemName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  lifeCycle?: Maybe<Scalars["String"]>;
  businessArea?: Maybe<Scalars["String"]>;
  schema?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  modelType?: Maybe<Scalars["String"]>;
  modelSubType?: Maybe<Scalars["String"]>;
  virtualModel?: Maybe<Scalars["String"]>;
  numberOfTables?: Maybe<Scalars["String"]>;
  numberOfVirtualModels?: Maybe<Scalars["String"]>;
  asof?: Maybe<Scalars["String"]>;
  dataFreeze?: Maybe<Scalars["String"]>;
  lastUpdate?: Maybe<Scalars["String"]>;
};

export type SpectrumTableQueryData = {
  __typename?: "SpectrumTableQueryData";
  schemaName?: Maybe<Scalars["String"]>;
  tables?: Maybe<Array<Maybe<SpectrumTableQueryDataTables>>>;
};

export type SpectrumTableQueryDataTables = {
  __typename?: "SpectrumTableQueryDataTables";
  name?: Maybe<Scalars["String"]>;
  sasName?: Maybe<Scalars["String"]>;
  sasLabel?: Maybe<Scalars["String"]>;
  oracleName?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  audited?: Maybe<Scalars["String"]>;
  blinded?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
};

export type SpectrumTableDetails = {
  __typename?: "SpectrumTableDetails";
  oracleName?: Maybe<Scalars["String"]>;
  sasName?: Maybe<Scalars["String"]>;
  sasLabel?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  audited?: Maybe<Scalars["String"]>;
  blinded?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  columns?: Maybe<Array<Maybe<SpectrumTableColumns>>>;
};

export type SpectrumTableColumns = {
  __typename?: "SpectrumTableColumns";
  name?: Maybe<Scalars["String"]>;
  sasName?: Maybe<Scalars["String"]>;
  sasLabel?: Maybe<Scalars["String"]>;
  sasFormat?: Maybe<Scalars["String"]>;
  length?: Maybe<Scalars["String"]>;
  dataType?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["String"]>;
};

export type ImportSpmReContentOutput = {
  __typename?: "ImportSpmReContentOutput";
  actionId: Scalars["ID"];
  actionStatus: ActionStatus;
};

export type ImportModspaceContentOutput = {
  __typename?: "ImportModspaceContentOutput";
  actionId: Scalars["ID"];
  actionStatus: ActionStatus;
};

export type AuditLogDownloadLinkOutput = {
  __typename?: "AuditLogDownloadLinkOutput";
  actionStatus: ActionStatus;
};

export type RestoreFileVersionOutput = {
  __typename?: "RestoreFileVersionOutput";
  actionId: Scalars["ID"];
  versionIds: Array<Scalars["ID"]>;
};

export type UploadFileInput = {
  name: Scalars["String"];
  parentFolder: Scalars["String"];
  activityId: Scalars["ID"];
  fileContent: Scalars["String"];
};

export type CreateUploadLinksInput = {
  activityId: Scalars["ID"];
  absolutePaths: Array<Maybe<Scalars["String"]>>;
  md5sums: Array<Maybe<Scalars["String"]>>;
  actionFileTypes?: Maybe<Array<Maybe<ActionFileType>>>;
  actionId?: Maybe<Scalars["ID"]>;
  qualityCheckStatus?: Maybe<QualityCheckStatus>;
  uncompressOnUpload?: Maybe<Scalars["Boolean"]>;
};

export type ForceRefreshPmxActivityInput = {
  activityId: Scalars["ID"];
};

export type RenameObjectsInput = {
  activityId: Scalars["ID"];
  absolutePath: Scalars["String"];
  newName: Scalars["String"];
  override?: Maybe<Scalars["Boolean"]>;
  comment?: Maybe<Scalars["String"]>;
};

export type CopyObjectsInput = {
  activityId: Scalars["ID"];
  absolutePaths: Array<Maybe<Scalars["String"]>>;
  destinationPath: Scalars["String"];
  override?: Maybe<Scalars["Boolean"]>;
  comment?: Maybe<Scalars["String"]>;
  repoIdOrigin?: Maybe<Scalars["ID"]>;
};

export type MoveObjectsInput = {
  activityId: Scalars["ID"];
  absolutePaths: Array<Maybe<Scalars["String"]>>;
  destinationPath: Scalars["String"];
  override?: Maybe<Scalars["Boolean"]>;
  comment?: Maybe<Scalars["String"]>;
};

export type HideObjectsInput = {
  activityId: Scalars["ID"];
  absolutePaths: Array<Maybe<Scalars["String"]>>;
  comment: Scalars["String"];
};

export type AddExternalUserInput = {
  email: Scalars["String"];
  username: Scalars["String"];
  name: Scalars["String"];
  company?: Maybe<Scalars["String"]>;
  telephone: Scalars["String"];
};

export type CreatePmxActivityInput = {
  trialNumber: Scalars["String"];
  bayNumber?: Maybe<Scalars["String"]>;
  projectId?: Maybe<Scalars["String"]>;
  trialShortDescription?: Maybe<Scalars["String"]>;
  isStandard?: Maybe<Scalars["Boolean"]>;
  readOnlyRepositories?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  source?: Maybe<ActivitySourceInput>;
};

export type ActivitySourceInput = {
  template?: Maybe<Scalars["String"]>;
  spmre?: Maybe<SpmReSourceInput>;
  modspace?: Maybe<ModspaceSourceInput>;
};

export type EditPmxActivityInput = {
  id: Scalars["ID"];
  trialNumber: Scalars["String"];
  bayNumber?: Maybe<Scalars["String"]>;
  projectId?: Maybe<Scalars["String"]>;
  trialShortDescription?: Maybe<Scalars["String"]>;
  isStandard?: Maybe<Scalars["Boolean"]>;
  readOnlyRepositories?: Maybe<Array<Maybe<Scalars["Int"]>>>;
};

export type DeletePmxActivityInput = {
  id: Scalars["ID"];
};

export type CreateFolderInput = {
  name: Scalars["String"];
  parentFolder: Scalars["String"];
  activityId: Scalars["ID"];
};

export type CreateArchiveInput = {
  activityId: Scalars["ID"];
  parentFolderInputs: Scalars["String"];
  relativePathInputs: Array<Maybe<Scalars["String"]>>;
  archiveName?: Maybe<Scalars["String"]>;
};

export type CreateConversationInput = {
  name: Scalars["String"];
  activityId: Scalars["ID"];
};

export type CreateCommentInput = {
  text: Scalars["String"];
  conversationId: Scalars["ID"];
  repliedToCommentId?: Maybe<Scalars["ID"]>;
  referencedFiles?: Maybe<Array<Maybe<Scalars["String"]>>>;
  referencedActions?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type EditCommentInput = {
  id: Scalars["ID"];
  text: Scalars["String"];
  referencedFiles?: Maybe<Array<Maybe<Scalars["String"]>>>;
  referencedActions?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type DeleteCommentInput = {
  id: Scalars["ID"];
};

export type ImportSwanContentInput = {
  activityId: Scalars["ID"];
  swanUser: Scalars["String"];
  encryptedPassword: Scalars["String"];
  parentFolder: Scalars["String"];
  absoluteSwanPaths: Array<Maybe<Scalars["String"]>>;
  qualityCheckStatus?: Maybe<QualityCheckStatus>;
  description?: Maybe<Scalars["String"]>;
};

export type ImportSpectrumDataInput = {
  username: Scalars["String"];
  encryptedPassword: Scalars["String"];
  tables: Array<Scalars["String"]>;
  schema: Scalars["String"];
  format?: Maybe<Scalars["String"]>;
  asof?: Maybe<Scalars["String"]>;
  dataType?: Maybe<Scalars["String"]>;
  qualityCheckStatus?: Maybe<QualityCheckStatus>;
  description?: Maybe<Scalars["String"]>;
  pmxActivityId: Scalars["Int"];
  absolutePath: Scalars["String"];
};

export type ImportSpectrumSchemaInput = {
  username: Scalars["String"];
  encryptedPassword: Scalars["String"];
  schemas: Array<Scalars["String"]>;
  format?: Maybe<Scalars["String"]>;
  asof?: Maybe<Scalars["String"]>;
  dataType?: Maybe<Scalars["String"]>;
  snapshotLabel?: Maybe<Scalars["String"]>;
  qualityCheckStatus?: Maybe<QualityCheckStatus>;
  description?: Maybe<Scalars["String"]>;
  pmxActivityId: Scalars["Int"];
  absolutePath: Scalars["String"];
};

export type CreateActionInput = {
  actionType: ActionType;
  actionFiles?: Maybe<Array<ActionFileInputType>>;
  activityId: Scalars["ID"];
  description?: Maybe<Scalars["String"]>;
  sourceFolder?: Maybe<Scalars["String"]>;
  sourceFiles?: Maybe<Array<Scalars["String"]>>;
  destination?: Maybe<Scalars["String"]>;
  additionalDetails?: Maybe<Scalars["String"]>;
  actionStatus?: Maybe<ActionStatus>;
  actionSource?: Maybe<ActionSource>;
  actionStatusDescription?: Maybe<Scalars["String"]>;
  qualityCheckStatus?: Maybe<QualityCheckStatus>;
  jobId?: Maybe<Scalars["ID"]>;
};

export type ActionFileInputType = {
  actionFileType: ActionFileType;
  versionId: Scalars["ID"];
};

export type UpdateActionStatusInput = {
  actionId: Scalars["ID"];
  actionStatus?: Maybe<ActionStatus>;
  actionStatusDescription?: Maybe<Scalars["String"]>;
};

export type EditAccessRightsInput = {
  repoId?: Maybe<Scalars["ID"]>;
  reader?: Maybe<Array<Maybe<Scalars["String"]>>>;
  contributor?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type DeleteAccessRightsInput = {
  repoId?: Maybe<Scalars["ID"]>;
  reader?: Maybe<Array<Maybe<Scalars["String"]>>>;
  contributor?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type ExportToSpmReInput = {
  activityId: Scalars["ID"];
  username: Scalars["String"];
  encryptedPassword: Scalars["String"];
  spmReFolder: Scalars["String"];
  absolutePaths: Array<Scalars["String"]>;
  targetFolder?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
};

export type ExportToModspaceInput = {
  activityId: Scalars["ID"];
  username: Scalars["String"];
  encryptedPassword: Scalars["String"];
  modspaceFolder: Scalars["String"];
  absolutePaths: Array<Scalars["String"]>;
  targetFolder?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
};

export type CreateMultipartUploadInput = {
  absolutePath: Scalars["String"];
  md5sum: Scalars["String"];
  activityId: Scalars["ID"];
  actionFileType?: Maybe<ActionFileType>;
  qualityCheckStatus?: Maybe<QualityCheckStatus>;
  uncompressOnUpload?: Maybe<Scalars["Boolean"]>;
  actionId?: Maybe<Scalars["ID"]>;
};

export type GetMultipartUploadLinksInput = {
  activityId: Scalars["ID"];
  absolutePath: Scalars["String"];
  uploadId: Scalars["String"];
  partNumbers: Array<Maybe<Scalars["Int"]>>;
};

export type CompleteMultipartUploadInput = {
  activityId: Scalars["ID"];
  absolutePath: Scalars["String"];
  uploadId: Scalars["String"];
  partNumbers: Array<Maybe<Scalars["Int"]>>;
  eTags: Array<Maybe<Scalars["String"]>>;
};

export type GetSpectrumStudyListInput = {
  username: Scalars["String"];
  encryptedPassword: Scalars["String"];
};

export type GetDatasetForStudyInput = {
  username: Scalars["String"];
  encryptedPassword: Scalars["String"];
  studyKey: Scalars["String"];
  system: Scalars["String"];
  therapeuticArea?: Maybe<Scalars["String"]>;
  indication?: Maybe<Scalars["String"]>;
};

export type EditActionInput = {
  actionId: Scalars["ID"];
  actionType?: Maybe<ActionType>;
  qualityCheckStatus?: Maybe<QualityCheckStatus>;
  description?: Maybe<Scalars["String"]>;
};

export type GetTableQueryDataInput = {
  username: Scalars["String"];
  encryptedPassword: Scalars["String"];
  schema: Scalars["String"];
};

export type GetSpectrumTableDetailsInput = {
  username: Scalars["String"];
  encryptedPassword: Scalars["String"];
  table: Scalars["String"];
  schema: Scalars["String"];
};

export type CreateExternalDownloadLinkInput = {
  repoId?: Maybe<Scalars["Int"]>;
  pmxActivityId?: Maybe<Scalars["Int"]>;
  versionIds: Array<Scalars["String"]>;
  absolutePath?: Maybe<Scalars["String"]>;
  emailAddress: Scalars["String"];
  additionalMessage?: Maybe<Scalars["String"]>;
};

export type CreateExternalUploadLinkInput = {
  pmxActivityId?: Maybe<Scalars["Int"]>;
  absolutePath?: Maybe<Scalars["String"]>;
  emailAddress: Scalars["String"];
  additionalMessage?: Maybe<Scalars["String"]>;
};

export type GetExternalDownloadLinkInput = {
  id: Scalars["ID"];
};

export type GetExternalUploadLinkInput = {
  id: Scalars["ID"];
  absolutePaths: Array<Maybe<Scalars["String"]>>;
  md5sums: Array<Maybe<Scalars["String"]>>;
  qualityCheckStatus?: Maybe<QualityCheckStatus>;
  uncompressOnUpload?: Maybe<Scalars["Boolean"]>;
  description?: Maybe<Scalars["String"]>;
};

export type UncompressArchiveInput = {
  pmxActivityId: Scalars["ID"];
  versionId: Scalars["String"];
  subfolder?: Maybe<Scalars["String"]>;
};

export type ImportSpmReContentInput = {
  activityId: Scalars["ID"];
  username: Scalars["String"];
  encryptedPassword: Scalars["String"];
  repoFolder?: Maybe<Scalars["String"]>;
  spmReFolder: Scalars["String"];
  spmRePaths?: Maybe<Array<Maybe<Scalars["String"]>>>;
  spmReRevision?: Maybe<Scalars["String"]>;
  qualityCheckStatus?: Maybe<QualityCheckStatus>;
  description?: Maybe<Scalars["String"]>;
};

export type SpmReSourceInput = {
  username: Scalars["String"];
  encryptedPassword: Scalars["String"];
  repoFolder?: Maybe<Scalars["String"]>;
  spmReFolder: Scalars["String"];
  spmRePaths?: Maybe<Array<Maybe<Scalars["String"]>>>;
  spmReRevision?: Maybe<Scalars["String"]>;
  qualityCheckStatus?: Maybe<QualityCheckStatus>;
  description?: Maybe<Scalars["String"]>;
};

export type ImportModspaceContentInput = {
  activityId: Scalars["ID"];
  username: Scalars["String"];
  encryptedPassword: Scalars["String"];
  repoFolder?: Maybe<Scalars["String"]>;
  modspaceFolder: Scalars["String"];
  modspacePaths?: Maybe<Array<Maybe<Scalars["String"]>>>;
  modspaceRevision?: Maybe<Scalars["String"]>;
  qualityCheckStatus?: Maybe<QualityCheckStatus>;
  description?: Maybe<Scalars["String"]>;
};

export type ModspaceSourceInput = {
  username: Scalars["String"];
  encryptedPassword: Scalars["String"];
  repoFolder?: Maybe<Scalars["String"]>;
  modspaceFolder: Scalars["String"];
  modspacePaths?: Maybe<Array<Maybe<Scalars["String"]>>>;
  modspaceRevision?: Maybe<Scalars["String"]>;
  qualityCheckStatus?: Maybe<QualityCheckStatus>;
  description?: Maybe<Scalars["String"]>;
};

export type RestoreFileVersionInput = {
  activityId: Scalars["ID"];
  versionIds: Array<Scalars["ID"]>;
  comment?: Maybe<Scalars["String"]>;
};

export type RestoreFilePathInput = {
  activityId: Scalars["ID"];
  paths: Array<Scalars["String"]>;
  historicDatetime?: Maybe<Scalars["String"]>;
  comment?: Maybe<Scalars["String"]>;
};

export type SetHistoryLabelInput = {
  actionId: Scalars["ID"];
  historyLabel: Scalars["String"];
};

export type Sort = {
  field: Scalars["String"];
  sort: Scalars["String"];
};

export type Filter = {
  items?: Maybe<Array<Maybe<FilterItem>>>;
  linkOperator: Scalars["String"];
};

export type FilterItem = {
  columnField: Scalars["String"];
  operatorValue: Scalars["String"];
  value?: Maybe<Scalars["String"]>;
};

export type GetAuditTrailByIdInput = {
  auditId: Scalars["ID"];
};

export type GetUserValidationsInput = {
  versionId?: Maybe<Scalars["ID"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
  pageSize?: Maybe<Scalars["Int"]>;
};

export type CreateUserValidationInput = {
  versionId: Scalars["ID"];
  validityStatus: ValidityStatus;
  description: Scalars["String"];
};
