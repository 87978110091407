import { Button, Col, Row, Spinner, Modal, Form, Table } from "react-bootstrap";
import Error from "../abstractComponents/error";
import { useState, useEffect } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useExportFilesMutation } from "../../backend/hooks/interactive/mutationExportFiles";
import UserManualLink from "../abstractComponents/usermanual";
import { ActionFileType } from "../../backend/types";

export function InteractiveFileTransferImportSimple(props: {
  environmentId: string;
  currentSubArray: any;
  selectedRows: string[];
}) {
  const { exportFiles, isLoadingExportFiles, errorExportFiles } =
    useExportFilesMutation();

  const [isSimpleModalVisible, setIsSimpleModalVisible] = useState(false);
  const [description, setDescription] = useState("");
  const [filesToBeDeleted, setFilesToBeDeleted] = useState<any[]>([]);
  const [filesToBeUploaded, setFilesToBeUploaded] = useState<any[]>([]);

  useEffect(() => {
    setFilesToBeDeleted(
      props.currentSubArray.filter(
        (diffObject: any) =>
          diffObject.state === "DELETED" &&
          props.selectedRows.indexOf(diffObject.path) !== -1
      )
    );

    const uploadedFiles = props.currentSubArray.filter(
      (diffObject: any) =>
        (diffObject.state === "MODIFIED" || diffObject.state === "ADDED") &&
        props.selectedRows.indexOf(diffObject.path) !== -1
    );

    setFilesToBeUploaded(uploadedFiles);
  }, [props.selectedRows]);

  const performSimpleUpload = () => {
    exportFiles({
      variables: {
        environmentId: props.environmentId,
        exportPaths: filesToBeUploaded.map((diffObject: any) => ({
          actionFileType: ActionFileType.Output,
          path: diffObject.fileName
        })),
        deletePaths: filesToBeDeleted.map((diffObject: any) => {
          return diffObject.fileName;
        }),
        qualityCheckStatus: null,
        description: description,
        actionType: "UPLOAD"
      }
    }).then(() => {
      setIsSimpleModalVisible(false);
    });
  };

  return (
    <>
      <Button
        id="proced_with_import_simple"
        className="button-secondary float-start"
        variant="primary"
        onClick={() => {
          setIsSimpleModalVisible(true);
        }}
      >
        <FileUploadIcon /> Simple Upload
      </Button>{" "}
      <Modal
        show={isSimpleModalVisible}
        onHide={() => {
          setIsSimpleModalVisible(false);
        }}
        dialogClassName="modal-75w interactive_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Simple File Upload from Interactive</Modal.Title>
          <UserManualLink
            url="/usermanual/datatransfer/#simple-upload"
            testId="um-ie-simple-upload"
            className="align-self-start ms-1"
          />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={3}>
              <Form.Label>Action Type</Form.Label>
              <Form.Select disabled>
                <option>Upload</option>
              </Form.Select>
            </Col>
            <Col xs={3}></Col>
            <Col xs={6}>
              <Form.Group className="mb-3" controlId="upload_description">
                <Form.Label>
                  Upload Description <span className="red_form">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  placeholder="Please enter a description"
                  value={description}
                  disabled={isLoadingExportFiles}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          {isLoadingExportFiles && (
            <Row className="loading_row">
              <Col className="text-center">
                <Spinner animation="border" className="spinner_color" />
                <br />
                Loading...
              </Col>
            </Row>
          )}
          <br />
          <br />
          <Button
            className="d-flex ml-auto mb-2 ms-auto"
            variant="primary"
            disabled={description === "" || isLoadingExportFiles}
            onClick={performSimpleUpload}
          >
            Perform Actions
          </Button>
          <h3 className="font-weight-normal">Preview - Resulting Actions:</h3>
          <Table
            striped
            bordered
            hover
            size="sm"
            className="resulting_action_preview"
          >
            <thead>
              <tr>
                <th>Action Type</th>
                <th>Files</th>
              </tr>
            </thead>
            <tbody>
              {filesToBeDeleted.length > 0 && (
                <tr>
                  <td rowSpan={filesToBeDeleted.length} valign="middle">
                    Hide
                  </td>
                  <td className="resulting_action_preview_div">
                    {filesToBeDeleted[0].path}
                  </td>
                </tr>
              )}
              {filesToBeDeleted.map((file: any, index: number) => {
                if (index === 0) {
                  return <></>;
                } else {
                  return (
                    <tr key={`deleted_${index}`}>
                      <td>{file.path}</td>
                    </tr>
                  );
                }
              })}

              {filesToBeUploaded.length > 0 && (
                <tr>
                  <td rowSpan={filesToBeUploaded.length} valign="middle">
                    Upload
                  </td>
                  <td className="resulting_action_preview_div">
                    {filesToBeUploaded[0].path}
                  </td>
                </tr>
              )}
              {filesToBeUploaded.map((file: any, index: number) => {
                if (index === 0) {
                  return <></>;
                } else {
                  return (
                    <tr key={`file_uploaded_${index}`}>
                      <td>{file.path}</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setIsSimpleModalVisible(false);
            }}
            disabled={isLoadingExportFiles}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {errorExportFiles && <Error error={errorExportFiles} />}
    </>
  );
}
