import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridSortDirection,
  GridSortModel,
  GridValueGetterParams
} from "@mui/x-data-grid-pro";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  ActionFile,
  ActionFileType,
  ActionSourceType,
  ActionSourceUI,
  ActionStatus,
  ActionTypeUI as ActionTypeHelper,
  ActionTypeType,
  QualityCheckStatusType,
  QualityCheckStatusUI,
  ValidityStatus
} from "../../backend/types";
import FileIcon from "../../components/abstractComponents/fileIcon";
import { checkIfActionIsNotEditable } from "../../helpers/actionHelper";
import { getKeyByValue } from "../../helpers/stringHelper";
import { CustomToolbar } from "../../helpers/tableHelper";
import { customFilters } from "../../helpers/tableViewHelper";
import EditAction from "../editAction";

// Component used as a popup when an error comes from GraphQL
export default function ActionTable(props: {
  rowsActions: any[];
  onNodeSelect: Function;
  setIsPreselectedRevision?: Function | undefined;
  revisionsToId?: any | undefined;
  areFilesSplit?: boolean | undefined;
  isExportEnabled?: boolean | undefined;
  onClose: Function;
  refetch?: Function;
}) {
  const handleGetRowIdActions = (row: any) => {
    return row.id;
  };
  const [sortModelActions, setSortModelActions] = useState<GridSortModel>([
    {
      field: "startDatetime",
      sort: "desc" as GridSortDirection
    }
  ]);

  const valueGetterFormatter = (
    actionFiles: ActionFile[],
    afType?: ActionFileType[]
  ) => {
    return [...actionFiles]
      .filter((af) => {
        if (!afType) return true;
        return afType.includes(af?.actionFileType);
      })
      .sort((af1, af2) => {
        return (
          Object.keys(ActionFileType).indexOf(af1?.actionFileType) -
          Object.keys(ActionFileType).indexOf(af2?.actionFileType)
        );
      })
      .map((af) => {
        if (af?.file) {
          let returnString = `${af.file.name} - (${af.file.revision})`;

          if (afType) {
            returnString += ` - ${getKeyByValue(
              ValidityStatus,
              af?.file.validity?.overallValidityStatus
            )}`;
          } else {
            returnString += ` - ${getKeyByValue(
              ActionFileType,
              af?.actionFileType
            )}`;
          }
          return returnString;
        }
        return "";
      })
      .join(";\n");
  };

  const renderCellFormatter = (
    id: String,
    actionFiles: [],
    hasAdditionalFilesLinked: Boolean,
    afType?: ActionFileType[]
  ) => {
    return (
      <div key={id} className="action_list_element">
        {[...actionFiles]
          .filter((af) => {
            if (!afType) return true;
            return afType.includes(af?.actionFileType);
          })
          .sort((af1: ActionFile, af2: ActionFile) => {
            return (
              Object.keys(ActionFileType).indexOf(af1?.actionFileType) -
              Object.keys(ActionFileType).indexOf(af2?.actionFileType)
            );
          })
          .map((af: ActionFile, index: number) => {
            if (af?.file) {
              return (
                <div className="no_line_hight" key={id + index}>
                  <b>
                    <FileIcon
                      fileName={af?.file.name}
                      overallValidityStatus={
                        af?.file.validity?.overallValidityStatus ?? null
                      }
                      fontSize={"small"}
                    />{" "}
                    <span
                      className="link-decoration"
                      onClick={() => {
                        props.onNodeSelect(
                          af?.file?.name,
                          af?.file?.versionId,
                          af?.file?.revision,
                          af?.file?.repo?.id
                        );
                        props.onClose();
                      }}
                    >
                      <span className="repo_name_link">
                        {af?.file?.repo?.name}/
                      </span>
                      {af?.file.name}
                    </span>
                  </b>{" "}
                  ({af?.file.revision}) -{" "}
                  {afType
                    ? getKeyByValue(
                        ValidityStatus,
                        af?.file.validity?.overallValidityStatus
                      )
                    : getKeyByValue(ActionFileType, af?.actionFileType)}
                </div>
              );
            } else {
              return "";
            }
          })}
        {hasAdditionalFilesLinked && <div>And More Files...</div>}
      </div>
    );
  };

  let columnsActions: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      flex: 1,
      filterOperators: customFilters,
      renderCell: (params: any) => (
        <>
          {params.row.id}{" "}
          <EditAction
            actionId={params.row.id}
            refetch={props.refetch}
            notEditableAction={checkIfActionIsNotEditable(
              params.row.actionType
            )}
          />
        </>
      )
    },
    props.revisionsToId && {
      field: "revision",
      headerName: "Revision",
      flex: 1,
      filterOperators: customFilters,
      valueGetter: (params: GridValueGetterParams) =>
        props.revisionsToId[params.row.id as keyof typeof props.revisionsToId]
    },
    {
      field: "actionType",
      headerName: "Type",
      flex: 1,
      filterOperators: customFilters,
      valueGetter: (params: GridValueGetterParams) =>
        ActionTypeHelper[params.row.actionType as ActionTypeType]
    },
    //if files are split, we put additional columns
    props.areFilesSplit
      ? {
          field: "pmxActivity", //The library does not permit multiple columns on the same property. So we chose other properties that we do not use
          headerName: "Output Files",
          width: 300,
          filterOperators: customFilters,
          valueGetter: (params: GridValueGetterParams) =>
            valueGetterFormatter(params.row.actionFiles, [
              ActionFileType.Output
            ]),
          renderCell: (params: any) =>
            renderCellFormatter(
              params.row.id,
              params.row.actionFiles,
              params.row.hasAdditionalFilesLinked,
              [ActionFileType.Output]
            )
        }
      : {
          field: "files",
          headerName: "Files",
          flex: 6,
          width: 150,
          filterOperators: customFilters,
          valueGetter: (params: any) =>
            valueGetterFormatter(params.row.actionFiles),
          renderCell: (params: any) =>
            renderCellFormatter(
              params.row.id,
              params.row.actionFiles,
              params.row.hasAdditionalFilesLinked
            )
        },
    props.areFilesSplit && {
      field: "inputFiles", //The library does not permit multiple columns on the same property. So we chose other properties that we do not use
      headerName: "Input Files",
      width: 300,
      filterOperators: customFilters,
      valueGetter: (params: GridValueGetterParams) =>
        valueGetterFormatter(params.row.actionFiles, [
          ActionFileType.Input,
          ActionFileType.Script
        ]),
      renderCell: (params: any) =>
        renderCellFormatter(
          params.row.id,
          params.row.actionFiles,
          params.row.hasAdditionalFilesLinked,
          [ActionFileType.Input, ActionFileType.Script]
        )
    },
    props.areFilesSplit && {
      field: "actionFile",
      headerName: "Describing Files",
      width: 300,
      filterOperators: customFilters,
      valueGetter: (params: GridValueGetterParams) =>
        valueGetterFormatter(params.row.actionFiles, [
          ActionFileType.Describing
        ]),
      renderCell: (params: any) =>
        renderCellFormatter(
          params.row.id,
          params.row.actionFiles,
          params.row.hasAdditionalFilesLinked,
          [ActionFileType.Describing]
        )
    },
    {
      field: "actionQualityCheckStatus",
      headerName: "QC Status",
      flex: 1,
      filterOperators: customFilters,
      valueGetter: (params: GridValueGetterParams) =>
        QualityCheckStatusUI[
          params.row.actionQualityCheckStatus as QualityCheckStatusType
        ]
    },
    {
      field: "startDatetime",
      headerName: "Executed At",
      flex: 1,
      filterOperators: customFilters
    },
    {
      field: "user",
      headerName: "User",
      flex: 1,
      filterOperators: customFilters,
      valueGetter: (params: GridValueGetterParams) => params.row.user.email
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      filterOperators: customFilters
    },
    {
      field: "jobId",
      headerName: "Job",
      filterOperators: customFilters,
      renderCell: (params: GridRenderCellParams<any, any, any>) =>
        params.row.jobId ? (
          <Link
            className="job_id_action"
            to={`/activity/${params.row.pmxActivity.id}/execution/${params.row.jobId}`}
          >
            {params.row.jobId}
          </Link>
        ) : (
          ""
        )
    },
    {
      field: "sourceFolder",
      headerName: "External Folder",
      flex: 1,
      filterOperators: customFilters,
      hide: true
    },
    {
      field: "sourceFiles",
      headerName: "External Files",
      flex: 1,
      filterOperators: customFilters,
      hide: true
    },
    {
      field: "action",
      headerName: "Activity",
      flex: 1,
      filterOperators: customFilters,
      hide: true,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.pmxActivity && params.row.pmxActivity.trialNumber
    },
    {
      field: "Repo",
      headerName: "Repository",
      flex: 1,
      filterOperators: customFilters,
      hide: true,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.pmxActivity && params.row.pmxActivity.mainRepository.name
    },
    {
      field: "actionSource",
      headerName: "Environment",
      flex: 1,
      filterOperators: customFilters,
      hide: true,
      valueGetter: (params: GridValueGetterParams) =>
        ActionSourceUI[params.row.actionSource as ActionSourceType]
    },
    {
      field: "actionStatus",
      headerName: "Status",
      flex: 1,
      filterOperators: customFilters,
      hide: true,
      valueGetter: (params: GridValueGetterParams) =>
        getKeyByValue(ActionStatus, params.row.actionStatus)
    },
    {
      field: "actionStatusDescription",
      headerName: "Status Description",
      flex: 1,
      filterOperators: customFilters,
      hide: true
    },
    {
      field: "additionalDetails",
      headerName: "Additional Details/Destination",
      filterOperators: customFilters,
      width: 180,
      hide: true
    }
  ];

  columnsActions = columnsActions.filter((column) => column !== undefined);

  return (
    <>
      {!props.rowsActions || !props.rowsActions ? (
        <>
          <br />
          <br />
          <p>No actions</p>
        </>
      ) : (
        <DataGridPro
          rows={props.rowsActions}
          className="styled_data_grid"
          columns={columnsActions}
          pageSize={25}
          disableSelectionOnClick
          autoHeight
          getRowHeight={() => "auto"}
          getRowId={handleGetRowIdActions}
          sortModel={sortModelActions}
          onSortModelChange={(model) => setSortModelActions(model)}
          components={
            props.isExportEnabled && {
              Toolbar: CustomToolbar
            }
          }
        />
      )}
    </>
  );
}
