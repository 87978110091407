import { gql, useMutation } from "@apollo/client";

export const CREATE_USER_VALIDATION = gql`
  mutation createUserValidation(
    $versionId: ID!
    $validityStatus: ValidityStatus!
    $description: String!
  ) {
    createUserValidation(
      input: {
        versionId: $versionId
        validityStatus: $validityStatus
        description: $description
      }
    ) {
      id
    }
  }
`;

export function useCreateUserValidation() {
  const [
    createUserValidation,
    {
      loading: isLoadingCreateUserValidation,
      error: errorCreateUserValidation,
      data: dataCreateUserValidation
    }
  ] = useMutation(CREATE_USER_VALIDATION);

  return {
    createUserValidation,
    isLoadingCreateUserValidation,
    errorCreateUserValidation,
    dataCreateUserValidation
  };
}
