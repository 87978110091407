import { Button, Col, Row, Spinner } from "react-bootstrap";
import RefreshIcon from "@mui/icons-material/Refresh";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useListDiffsQuery } from "../../backend/hooks/interactive/queryListDiffs";
import { useCreateDifferenceMutation } from "../../backend/hooks/interactive/mutationCreateDifference";
import Error from "../abstractComponents/error";
import { useState, useEffect, useContext } from "react";
import {
  DataGridPro,
  GridColDef,
  GridRowId,
  GridRowParams,
  GridSelectionModel
} from "@mui/x-data-grid-pro";
import FileIcon from "../abstractComponents/fileIcon";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import {
  renderTreeNodesInteractive,
  StyledTreeItem
} from "../../helpers/treeHelper";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import { useImportFilesMutation } from "../../backend/hooks/interactive/mutationImportFiles";
import { InteractiveFileTransferImportSimple } from "./interactiveFileTransferImportSimple";
import { InteractiveFileTransferImportAction } from "./interactiveFileTransferImportAction";
import { BrowsePmxActivityContext } from "../../contexts/browsePmxActivityProvider";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { formatStringToDateTime } from "../../helpers/stringHelper";
import { Stack } from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  compressedFileExtensions,
  excelFormatsExtensions,
  imageExtensions,
  installersExtensions,
  isFileEndsWith,
  moviesExtensions
} from "../../helpers/fileHelper";
import Success from "../abstractComponents/success";
import {
  DifferenceLocation,
  InteractiveFileInput
} from "../../backend/interactiveTypes";
import { ValidityStatus } from "../../backend/types";
import UserManualLink from "../abstractComponents/usermanual";

const organizeTreeHierarchy = (fileList: any[]) => {
  const folderStructure: any = [];

  fileList.forEach((item: any) => {
    const nameSplit = item.path.split("/");
    const currentName = String(nameSplit.pop());

    let currentSubArray = folderStructure;
    //for each internal folder, we parse through the tree to look for it
    nameSplit.forEach((name: string) => {
      const foundObject = currentSubArray.find(
        (element: any) => element.name === name
      );

      if (foundObject) {
        currentSubArray = foundObject.content;
      } else {
        currentSubArray.push({
          name: name,
          path: item.path.split(name)[0] + name,
          content: [],
          isDir: true
        });

        currentSubArray = currentSubArray.find(
          (element: any) => element.name === name
        ).content;
      }
    });

    const foundObject = currentSubArray.find(
      (element: any) => element.name === currentName
    );

    //fixing folders appearing as empty files
    if (!currentName) {
      return;
    }

    if (!foundObject) {
      currentSubArray.push({
        ...item,
        name: currentName,
        content: [],
        path: item.path
      });
    } else {
      //find and replace objec in Array
      for (let i = 0; i < currentSubArray.length; i++) {
        if (currentSubArray[i].name === currentName) {
          currentSubArray[i] = {
            ...item,
            content: foundObject.content,
            name: currentName
          };
          break;
        }
      }
    }
  });

  return folderStructure;
};

export function InteractiveFileTransfer(props: { environmentId: string }) {
  const { activityId } = useParams<{ activityId: string }>();
  const history = useHistory();
  const [shouldPollDifferences, setShouldPollDifferences] =
    useState<boolean>(false);
  const {
    createDifference,
    differenceCreated,
    isLoadingCreateDifference,
    errorCreateDifference
  } = useCreateDifferenceMutation();

  const [isPhilTheDestination, setIsPhilTheDestination] = useState(true);
  const { refetchDiff, diffs, isLoadingListDiffs, errorListDiffs } =
    useListDiffsQuery(props.environmentId, shouldPollDifferences);

  const [currentSubArray, setCurrentSubarray] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<
    InteractiveFileInput[] | null
  >(null);
  const [checkedFolders, setCheckedFolders] = useState<string[]>([]);
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(0);
  const refetchDiffState = useLocation().state as {
    refetchDiffState: boolean;
  };

  const { repos } = useContext(BrowsePmxActivityContext);

  useEffect(() => {
    if (refetchDiffState) {
      window.history.replaceState({ refetchDiffState: false }, "");
      computeNewDifference();
    }
  }, [refetchDiffState]);

  useEffect(() => {
    if (diffs?.differences?.length > 0) {
      const currentArray: any[] = [];

      diffs?.differences.forEach((file: any) => {
        if (
          (isPhilTheDestination && file.location === DifferenceLocation.Phil) ||
          (!isPhilTheDestination && file.location === DifferenceLocation.Ie)
        ) {
          return;
        }

        let nameSplit = file.key.split("/");
        const repoName = nameSplit[0];
        nameSplit.shift();
        const fileName = nameSplit.join("/");

        currentArray.push({
          path: file.key,
          state: file.type,
          lastUpdated: file.modifiedAt,
          isConflict: file.conflict,
          repoName: repoName,
          repoId:
            repos.length > 0 &&
            repos.find((repoData: any) => repoData?.repo?.name === repoName)
              ?.repo?.id,
          fileName: fileName,
          isDir: file.key.endsWith("/")
        });
      });

      setCurrentSubarray(currentArray);
    }

    if (diffs?.stoppedAt || (!diffs.diffId && !diffs.stoppedAt)) {
      setShouldPollDifferences(false);
    } else {
      setShouldPollDifferences(true);
    }
  }, [diffs, isPhilTheDestination]);

  const handleSelectMultipleRows = (e: GridSelectionModel) => {
    const constructRows: InteractiveFileInput[] = e.map((row: GridRowId) => {
      const nameSplit: string[] = row.toString().split("/");

      return {
        repoName: nameSplit.shift() || "",
        path: nameSplit.join("/") || ""
      };
    });
    setSelectedRows(constructRows);
  };

  useEffect(() => {
    const selectedRowsParse: Array<InteractiveFileInput> = [];
    checkedFolders.forEach((folderName) => {
      currentSubArray.forEach((item: any) => {
        if (item.path.startsWith(folderName + "/")) {
          selectedRowsParse.push({
            repoName: item.repoName,
            path: item.fileName
          });
        }
      });
    });

    setSelectedRows(selectedRowsParse);
  }, [checkedFolders]);

  const generateCompareUrl = (params: any): string => {
    return (
      `/activity/${activityId}/interactive/` +
      props.environmentId +
      "/compare?" +
      encodeURIComponent(`repoId=${params.row.repoId}`) +
      encodeURIComponent(`&repoName=${params.row.repoName}`) +
      encodeURIComponent(`&path=${params.row.fileName}`) +
      encodeURIComponent(`&phil=${isPhilTheDestination ? true : false}`) +
      encodeURIComponent(`&conflict=${params.row.isConflict}`)
    );
  };

  const isFileHidden = (row: any) => {
    const excludedExtensions = [
      imageExtensions,
      compressedFileExtensions,
      moviesExtensions,
      installersExtensions,
      excelFormatsExtensions
    ].flat();
    excludedExtensions.push(".pdf");
    return (
      (row.state.toLowerCase() === "modified" &&
        !isFileEndsWith(row.fileName, excludedExtensions)) ||
      (row.isConflict && !isFileEndsWith(row.fileName, excludedExtensions))
    );
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 4,
      renderCell: (params: any) => (
        <>
          <FileIcon
            fileName={params.row.path}
            overallValidityStatus={ValidityStatus.NotSet}
            fontSize={"large"}
            className={"description_icon"}
          />{" "}
          {params.row.path}
        </>
      ),
      valueGetter: (params: any) => params.row.path
    },
    {
      field: "Compare",
      headerName: "Compare",
      flex: 1,
      renderCell: (params: any) =>
        isFileHidden(params.row) && (
          <Button
            onClick={() => history.push(generateCompareUrl(params))}
            data-testid="compare-button"
          >
            Compare
          </Button>
        )
    },
    {
      field: "state",
      headerName: "State",
      flex: 1,
      renderCell: (params: any) => (
        <span
          className={
            params.row.state.toLowerCase() === "added"
              ? "interactive_item_state_created"
              : params.row.state.toLowerCase() === "deleted"
                ? "interactive_item_state_deleted"
                : "interactive_item_state_modified"
          }
        >
          {params.row.state}
          {params.row.isConflict && (
            <>
              {" "}
              -{" "}
              <span className="interactive_item_state_deleted isConflict">
                Conflict
              </span>
            </>
          )}
        </span>
      )
    },
    {
      field: "lastUpdated",
      headerName: "Last Updated Date",
      flex: 2,
      valueGetter: (params: any) =>
        formatStringToDateTime(params.row.lastUpdated * 1000)
    }
  ];

  const { importFiles, isLoadingImportFiles, errorImportFiles } =
    useImportFilesMutation();

  const hasConflictInImport = () => {
    let hasConflictInImportEndData = false;
    currentSubArray.forEach((fileListing: any) => {
      if (
        selectedRows?.find((row: InteractiveFileInput) =>
          row.path.includes(fileListing.path)
        ) &&
        fileListing.isConflict
      ) {
        hasConflictInImportEndData = true;
      }
    });

    if (
      !hasConflictInImportEndData ||
      (hasConflictInImportEndData &&
        window.confirm(
          "Are you sure you want to overwrite the files already in Interactive?"
        ))
    ) {
      return true;
    }

    return false;
  };

  const triggerImport = () => {
    if (hasConflictInImport()) {
      importFiles({
        variables: {
          environmentId: props.environmentId,
          importPaths: selectedRows
        }
      });
    }
  };

  const computeNewDifference = () => {
    createDifference({
      variables: {
        environmentId: props.environmentId
      }
    }).then(() => {
      setShouldPollDifferences(true);
      refetchDiff();
    });
  };

  const RefreshButton = () => {
    return (
      <Button
        id="interactive_file_transfer"
        className="button-secondary float-end"
        variant="outline-primary"
        onClick={computeNewDifference}
      >
        <RefreshIcon /> Reload Diff
      </Button>
    );
  };

  const convertSelectedRowsToPaths = (selectedRows: InteractiveFileInput[]) => {
    return selectedRows?.map((row) => `${row.repoName}/${row.path}`);
  };
  return (
    <div className="interactive_file_transfer">
      {shouldPollDifferences ||
      isLoadingListDiffs ||
      isLoadingImportFiles ||
      isLoadingCreateDifference ||
      shouldPollDifferences ? (
        <Row className="loading_row">
          <Col className="text-center">
            <Spinner animation="border" className="spinner_color" />
            <br />
            Loading Differences...
          </Col>
        </Row>
      ) : (
        <>
          <Row className="interactive_transfer_source_graph">
            <Col xs={1}>
              <div className="interactive_transfer_source">Source</div>
              {isPhilTheDestination ? "Interactive" : "PHIL"}
            </Col>
            <Col xs={1} className="text-center">
              <DoubleArrowIcon className="interactive_direction_display" />
            </Col>
            <Col xs={1}>
              <div className="interactive_transfer_source">Destination</div>
              {isPhilTheDestination ? "PHIL" : "Interactive"}
            </Col>
            <Col xs={1} className="interactive_transfer_source_button">
              <Button
                variant="outline-dark"
                onClick={() => {
                  setIsPhilTheDestination(!isPhilTheDestination);
                  setSelectedRows([]);
                }}
              >
                <CompareArrowsIcon /> Swap
              </Button>
            </Col>
            {diffs.stoppedAt ? (
              <Col xs={2}>
                <div className="interactive_transfer_source">
                  Last Calculated Difference
                </div>
                {formatStringToDateTime(diffs?.stoppedAt * 1000)}
              </Col>
            ) : (
              <Col xs={2}>
                <div className="interactive_transfer_source">
                  Difference Calculation Started At
                </div>
                {formatStringToDateTime(diffs?.startedAt * 1000)}
              </Col>
            )}
            <Col xs={4}>
              {selectedRows && selectedRows.length > 0 && (
                <div className="d-flex justify-content-end">
                  {isPhilTheDestination ? (
                    <>
                      <InteractiveFileTransferImportSimple
                        environmentId={props.environmentId}
                        currentSubArray={currentSubArray}
                        selectedRows={convertSelectedRowsToPaths(selectedRows)}
                      />
                      <InteractiveFileTransferImportAction
                        environmentId={props.environmentId}
                        currentSubArray={currentSubArray}
                        selectedRows={convertSelectedRowsToPaths(selectedRows)}
                      />
                    </>
                  ) : (
                    <Button
                      id="proced_with_import"
                      className="button-secondary"
                      variant="primary"
                      onClick={triggerImport}
                    >
                      <ArrowForwardIcon /> Import Selected Files
                    </Button>
                  )}
                </div>
              )}
            </Col>
            <Col xs={2}>
              <UserManualLink
                url="/usermanual/datatransfer"
                testId="um-actions"
              />
              <RefreshButton />
            </Col>
          </Row>

          <Row className="interactive_transfer_source_display">
            <Col xs={3}>
              {currentSubArray.length > 0 &&
                organizeTreeHierarchy(currentSubArray)?.map((diffItem: any) => (
                  <SimpleTreeView
                    key={`${diffItem.name}-tree`}
                    slots={{
                      expandIcon: AddBoxOutlinedIcon,
                      collapseIcon: IndeterminateCheckBoxOutlinedIcon
                    }}
                    defaultExpandedItems={["/"]}
                    className={`mt-2 tree-view`}
                    disableSelection
                  >
                    <StyledTreeItem
                      key={"/"}
                      itemId={"/"}
                      label={
                        <>
                          <CenterFocusStrongIcon className="grey_color" />{" "}
                          {diffItem.name}{" "}
                        </>
                      }
                    >
                      {renderTreeNodesInteractive(
                        diffItem.content.sort(function (a: any, b: any) {
                          if (a.isDir && !b.isDir) {
                            return -1;
                          }
                          if (b.isDir && !a.isDir) {
                            return 1;
                          }

                          return a.name.localeCompare(b.name);
                        }),
                        checkedFolders,
                        setCheckedFolders,
                        (isPhilTheDestination &&
                          repos.length > 0 &&
                          diffItem.name ===
                            repos.find(
                              (repo: any) => !repo?.isReadOnlyRepository
                            )?.repo.name) ||
                          !isPhilTheDestination
                      )}
                    </StyledTreeItem>
                  </SimpleTreeView>
                ))}
            </Col>
            <Col xs={9}>
              <DataGridPro
                rows={currentSubArray}
                columns={columns}
                getRowId={(row) => row.path}
                rowHeight={50}
                autoHeight
                disableSelectionOnClick
                selectionModel={convertSelectedRowsToPaths(selectedRows || [])}
                onSelectionModelChange={(e: GridSelectionModel) =>
                  handleSelectMultipleRows(e)
                }
                checkboxSelection
                isRowSelectable={(params: GridRowParams) =>
                  (isPhilTheDestination &&
                    repos.length > 0 &&
                    params.row.path.startsWith(
                      repos.find((repo: any) => !repo?.isReadOnlyRepository)
                        ?.repo.name
                    )) ||
                  !isPhilTheDestination
                }
                initialState={{
                  sorting: {
                    sortModel: [{ field: "name", sort: "asc" }]
                  }
                }}
                components={{
                  NoRowsOverlay: () => (
                    <Stack
                      height="100%"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {currentSubArray.length === 0 &&
                        `No Differences in ${
                          isPhilTheDestination ? "Interactive" : "PhIL"
                        }.`}
                    </Stack>
                  )
                }}
                pagination
                pageSize={pageSize}
                page={page}
                onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(pageSize) => setPageSize(pageSize)}
                rowsPerPageOptions={[50, 100, 500, 1000]}
              />
            </Col>
          </Row>
        </>
      )}

      {(errorListDiffs || errorImportFiles || errorCreateDifference) && (
        <Error
          error={errorListDiffs || errorImportFiles || errorCreateDifference}
        />
      )}
      {differenceCreated && (
        <Success message="Difference Calculation Request Created Successfully" />
      )}
    </div>
  );
}
